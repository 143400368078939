import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";

const sizes = { auto: "w-auto p-0", sm: "w-[35px] p-0", md: "w-[50px]", full: "w-full"};
const buttonSizes = { auto: "w-auto", sm: "w-[18px]", md: "w-[25px]", full: "w-auto"};

const Number = ({
  value: initialValue = 0,
  name = "custom-input-number",
  label = "",
  className,
  textColor = "text-gray-700 hover:text-black focus:text-black",
  borderColor = "border border-gray-200 focus:border-gray-200",
  buttonColor = "bg-gray-200 text-gray-600 hover:text-gray-200 hover:bg-gray-700",
  simbolMinus = "−",
  simbolPlus = "+",
  size = "md",
  errors = [],
  min,
  max,
  onChange,
}) => {  
  const [value, setValue] = useState(initialValue);

  function decrement() {
    if (value > min) {
      setValue(value - 1);
      onChange && onChange(value - 1);
    }
  }

  function increment() {
    if (value < max) {
      setValue(value + 1);
      onChange && onChange(value + 1);
    }
  }

  return (
    <>
      <div className={`custom-number-input ${className}`}>
        <label htmlFor={name} className="w-full text-gray-700 text-sm font-semibold">{label}
        </label>
        <div className="flex flex-row w-auto rounded-lg relative bg-transparent h-[35px]">
          <button onClick={decrement} className={`${buttonColor} ${(size && buttonSizes[size]) || ""} rounded-l cursor-pointer outline-none`}>
            <span className="m-auto text-lg">{simbolMinus}</span>
          </button>
          <input 
            type="text" 
            className={`outline-none focus:outline-none text-center ${(size && sizes[size]) || ""} font-semibold text-md md:text-basecursor-default flex items-center bg-transparent ${textColor} ${borderColor}`}
            name={name} 
            value={value}
            onChange={onChange}
            readOnly>
          </input>
          <button onClick={increment} className={`${buttonColor} ${(size && buttonSizes[size]) || ""} rounded-r cursor-pointer`}>
            <span className="m-auto text-lg">{simbolPlus}</span>
          </button>
        </div>
      </div>
      <ErrorMessage errors={errors} />  
    </>
  );
};

Number.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.oneOf(["auto", "sm", "md", "full"]),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

Number.defaultProps = {
  value: 0,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  size: "md",
  className: "",
  onChange: () => {},
};

export { Number };

import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";
import { TextareaAutosize } from '@mui/base';

const variants = {
  outline: {
    blue_gray_50: "text-gray-700",
  },
  fill: { white_A700: "bg-white-A700 text-blue_gray-200_01" },
};
const sizes = {
  xxs: "px-1",
  xs: "p-2",
  sm: "p-[11px]",
  md: "p-[15px]",
};

const TextArea = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      id = "",
      placeholder = "",
      errors = [],
      label = "",
      prefix,
      suffix,
      size = "md",
      variant = "fill",
      color = "white_A700",
      defaultValue,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} 
              ${variants[variant]?.[color] || ""} 
              ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <TextareaAutosize 
            ref={ref}
            className={`${className} border-0 bg-transparent w-full resize-none`}
            name={name}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            minRows={1}
            maxRows={10}
            {...restProps}
          >
          </TextareaAutosize>
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  },
);

TextArea.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md"]),
  variant: PropTypes.oneOf(["outline", "fill"]),
  color: PropTypes.oneOf(["blue_gray_50", "white_A700", "transparent"]),
};

export { TextArea };

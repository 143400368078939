import React from 'react';
import { InlineMath } from 'react-katex';

const Latex = ({ enunciado }) => {
  if (!enunciado) return null;
  const partes = enunciado.split('&') || [];
  const infoLatex = partes.map((parte, index) =>
    index % 2 === 1 ? <InlineMath math={String.raw`${parte.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} key={index}/> : parte
  );
  return (
    <div>
      {infoLatex}
    </div>
  );
};

export { Latex };

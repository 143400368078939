import React from "react";

export const ErrorMessage = ({ errors = {}, className = "" }) => {
  return (
    errors && Object.keys(errors).length > 0 && (
      <div
        className={`rounded-md bg-pink-300 text-white-A700 text-left font-semibold text-sm w-full my-1 p-2 ${className}`}
      >
        {(Array.isArray(errors)) && Object.keys(errors).map((key) => (
          <div key={key}>- {errors[key]}</div>
        )) || (
          <div>- {errors}</div>
        )}
      </div>
    )
  );
};

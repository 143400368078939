import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";

const variants = {
  fill: {
    pink_300: "bg-pink-300 shadow-bs text-white-A700",
    white_A700: "bg-white-A700 text-indigo-A900",
    amber_500: "bg-amber-500 text-gray-700",
    blue_gray_50: "bg-blue_gray-50",
    indigo_A200: "bg-indigo-A200 text-white-A700 hover:bg-indigo-A900",
    indigo_A700: "bg-indigo-A900 text-white-A700",
    teal_A700: "bg-white-A700 text-teal-A700",
    gray_100: "bg-white-A700 text-gray-700",
  },
};
const shapes = { round: "rounded-[3px]" };
const sizes = { xs: "pl-px py-px", sm: "p-2" };

const CheckBox = React.forwardRef(
  (
    {
      inputClassName = "",
      className = "",
      name = "",
      children,
      label = "",
      errors = [],
      shape = "round",
      size = "sm",
      variant = "fill",
      color = "white_A700",
      id = "checkbox_id",
      onChange,
      ...restProps
    },
    ref,
  ) => {
    const handleChange = (e) => {
      if (onChange) onChange(e?.target?.checked);
    };

    return (
      <>
        <div className={className}>
          <input
            className={`${inputClassName} ${(shape && shapes[shape]) || ""} ${
              (size && sizes[size]) || ""
            } ${(variant && variants[variant]?.[color]) || ""}`}
            ref={ref}
            type="checkbox"
            name={name}
            onChange={handleChange}
            {...restProps}
            id={id}
          />
          <label htmlFor={id} className="ml-2">{label}</label>
        </div>
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  },
);

CheckBox.propTypes = {
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "sm"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf([
    "pink_300",
    "white_A700",
    "amber_500",
    "blue_gray_50",
    "indigo_A200",,
    "indigo_A700",
    "teal_A700",
    "gray_100",
  ]),
};

export { CheckBox };

import React from "react";
import { FaSpinner  } from "react-icons/fa";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import WithAuth from './WithAuth';

const LobbyView = React.lazy(
  () => import("pages/lobby")
);
const LibraryView = React.lazy(
  () => import("pages/library")
);
const CallbackView = React.lazy(
  () => import("pages/auth/callback")
);
const LogoutView = React.lazy(
  () => import("pages/auth/logout")
);
/* Guides */
const GuidesOasView = React.lazy(
  () => import("pages/guides/oas"),
);
const GuidesIesView = React.lazy(
  () => import("pages/guides/ies"),
);
const GuidesTypesView = React.lazy(
  () => import("pages/guides/types"),
);
const GuidesPrevView = React.lazy(
  () => import("pages/guides/prev")
);

/* Activities */
const ActivitiesOasView = React.lazy(
  () => import("pages/activities/oas"),
);
const ActivitiesIesView = React.lazy(
  () => import("pages/activities/ies"),
);
const ActivitiesTypesView = React.lazy(
  () => import("pages/activities/types"),
);
const ActivitiesPrevView = React.lazy(
  () => import("pages/activities/prev")
);

/* Presentations */
const PresentationsOasView = React.lazy(
  () => import("pages/presentations/oas"),
);
const PresentationsThemeView = React.lazy(
  () => import("pages/presentations/theme"),
);
const PresentationsPrevView = React.lazy(
  () => import("pages/presentations/prev")
);
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<FaSpinner className="loading-icon" size="20" />}>
      <Router>
        <Routes>
          <Route path="/" element={<LobbyView />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/logout"
            element={<WithAuth><LogoutView /></WithAuth>}
          />
          <Route
            path="/callback"
            element={<CallbackView />}
          />
          <Route 
            path="/library" 
            element={<WithAuth><LibraryView /></WithAuth>} 
          />
          <Route
            path="/guides/oas"
            element={<WithAuth><GuidesOasView /></WithAuth>}
          />
          <Route
            path="/guides/ies"
            element={<WithAuth><GuidesIesView /></WithAuth>}
          />
          <Route
            path="/guides/types"
            element={<WithAuth><GuidesTypesView /></WithAuth>}
          />
          <Route
            path="/guides/preview"
            element={<WithAuth><GuidesPrevView /></WithAuth>}
          />
          <Route
            path="/activities/oas"
            element={<WithAuth><ActivitiesOasView /></WithAuth>}
          />
          <Route
            path="/activities/ies"
            element={<WithAuth><ActivitiesIesView /></WithAuth>}
          />
          <Route
            path="/activities/types"
            element={<WithAuth><ActivitiesTypesView /></WithAuth>}
          />
          <Route
            path="/activities/preview"
            element={<WithAuth><ActivitiesPrevView /></WithAuth>}
          />
          <Route
            path="/presentations/oas"
            element={<WithAuth><PresentationsOasView /></WithAuth>}
          />
          <Route
            path="/presentations/theme"
            element={<WithAuth><PresentationsThemeView /></WithAuth>}
          />
          <Route
            path="/presentations/preview"
            element={<WithAuth><PresentationsPrevView /></WithAuth>}
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;

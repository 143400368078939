import React from "react";
import PropTypes from "prop-types";

const shapes = { square: "rounded-none", round: "rounded-full" };
const variants = {
  fill: {
    pink_300: "bg-pink-300 shadow-bs text-white-A700",
    white_A200: "bg-white-A700 text-gray-700 hover:bg-gray-300_01",
    white_A300: "bg-white-A700 text-gray-800 hover:bg-gray-50",
    white_A700: "bg-white-A700 text-indigo-A200 hover:bg-gray-50",
    white_P300: "bg-white-A700 text-pink-300 hover:bg-gray-50",
    amber_500: "bg-amber-500 text-gray-700",
    blue_gray_50: "bg-blue_gray-50",
    indigo_A200: "bg-indigo-A200 text-white-A700 hover:bg-indigo-A800",
    indigo_A700: "bg-indigo-A900 text-white-A700",
    teal_100: "bg-teal-100 text-gray-900",
    teal_A700: "bg-teal-A700 text-white-A700 hover:bg-teal-300",
    gray_100: "bg-gray-100",
    indigo_A800: "bg-indigo-A800 text-white-A700",
    transparent: "bg-transparent text-white-A700",
  },
};
const sizes = { xxs: "px-5", xs: "p-[5px]", sm: "p-[9px]", md: "p-[15px]", lg: "p-[18px]", xl: "px-[25px] py-[5px]"};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "fill",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`items-center !leading-none gap-x-1 ${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
        {!!leftIcon && leftIcon}
        {children}
        {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "lg", "xl"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf([
    "pink_300",
    "white_A200",
    "white_A300",
    "white_A700",
    "white_P300",
    "amber_500",
    "blue_gray_50",
    "indigo_A200",
    "indigo_A300",
    "indigo_A700",
    "teal_100",
    "teal_A700",
    "gray_100",
    "indigo_A800",
    "transparent",
  ]),
};

export { Button };

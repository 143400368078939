import React from 'react';
import { Text, Button, TextArea, Latex, CustomSkeleton } from 'components';
import { FaInfo  } from "react-icons/fa";
import { IoPencil, IoCheckmarkCircleOutline } from "react-icons/io5";
import { Tooltip } from '@mui/material';

function Activities ({ data, setData, isClicked, setIsClicked, loadingProgress, idSubject }) {
  
  const habilidadView = (dataH, key) => (
    <div className={`edit w-[24px] rounded-tl-lg flex-2 justify-center ${isClicked === `${key}` ? 'fade-in' : 'fade-out'}`}>
      <Tooltip title={dataH}>
        <div className='h-full'><FaInfo className="text-indigo-A200 my-3 mx-auto" size="15" /></div>
      </Tooltip>
    </div>
  );

  const buttonsView = (index) => (
    <div className={`edit flex flex-col gap-5 items-center justify-start px-1 py-1 rounded-lg w-auto border-transition bg-transition ${isClicked === `${index}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'}`}>
      {(isClicked !== `${index}`) && (
        <Button 
          className={`p-1 rounded-lg border border-solid`} 
          shape="round"
          color="white_A700"
          variant="fill"
          disabled={ (loadingProgress === 100) ? false : true }
          onClick={(e) => {
            e.stopPropagation();
            setIsClicked(`${index}`);
          }} >
          <IoPencil className={`text-gray-600 ${(loadingProgress === 100) ? 'hover:text-gray-900' : ''} text-xs`} size="20" title="Editar" />
        </Button>
      ) || (
        <Button 
          className={`p-1`} 
          shape="round"
          color="indigo_A200"
          variant="fill"
          onClick={(e) => {
            e.stopPropagation();
            if(index === 'duracion' || index === 'descripcion' || index === 'analogias' || index === 'conexiones_con_otras_asignaturas' || index === 'conexiones_con_vida_diaria'){
              const vlor = document.getElementById(index).value;
              if (vlor.trim() !== ''){
                data[index]= vlor.trim();
              }
              setData(prevData => ({ ...prevData, [index]: data[index] }));
            } else if(index === 'materiales'){
              const vlor = document.getElementById(index).value;
              if (vlor.trim() !== ''){
                data[index]= vlor.split(',');
              }
              setData(prevData => ({ ...prevData, [index]: data[index] }));
            } else {
              const vlorTitle = document.getElementById(`titulopregunta${index}`).value;
              if (vlorTitle.trim() !== ''){
                data.instrucciones[index].titulo = vlorTitle.trim();
              }
              const vlorDesc = document.getElementById(`enunciadopregunta${index}`).value;
              if (vlorDesc.trim() !== ''){
                data.instrucciones[index].detalle = vlorDesc.trim();
              }
              setData(prevData => ({ ...prevData, 
                instrucciones: data.instrucciones
              }));
            }
            setIsClicked(null);
          }} >
          <IoCheckmarkCircleOutline className={`text-white-A700 text-xs`} size="20" title="Guardar" />
        </Button>
      )}
    </div>
  );

  const contentActivities = (
    (data && data !== undefined) && (
      <div className="flex flex-col gap-4 items-start justify-start w-full">
        {(data.descripcion !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `descripcion` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'descripcion')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `descripcion` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Description of the activity" : "Descripción de la actividad"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `descripcion` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `descripcion` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `descripcion`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`descripcion`}
                                id={`descripcion`}
                                defaultValue={data.descripcion}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `descripcion`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.descripcion}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`descripcion`)}
          </div>
        )}
        {(data.materiales !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `materiales` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'materiales')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `materiales` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Materials" : "Materiales"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `materiales` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `materiales` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `materiales`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`materiales`}
                                id={`materiales`}
                                defaultValue={data.materiales}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `materiales`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.materiales.join(', ')}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`materiales`)}
          </div>
        )}
        {(data.duracion !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `duracion` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'duracion')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `duracion` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Duration" : "Duración"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `duracion` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `duracion` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `duracion`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`duracion`}
                                id={`duracion`}
                                defaultValue={data.duracion}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `duracion`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.duracion}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`duracion`)}
          </div>
        )}
        {(data.analogias !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `analogias` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'analogias')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `analogias` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Analogies" : "Analogías"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `analogias` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `analogias` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `analogias`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`analogias`}
                                id={`analogias`}
                                defaultValue={data.analogias}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `analogias`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.analogias}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`analogias`)}
          </div>
        )}
        {(data.conexiones_con_otras_asignaturas !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `conexiones_con_otras_asignaturas` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'conexiones_con_otras_asignaturas')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `conexiones_con_otras_asignaturas` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Connections with other subjects" : "Conexiones con otras asignaturas"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `conexiones_con_otras_asignaturas` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `conexiones_con_otras_asignaturas` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `conexiones_con_otras_asignaturas`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`conexiones_con_otras_asignaturas`}
                                id={`conexiones_con_otras_asignaturas`}
                                defaultValue={data.conexiones_con_otras_asignaturas}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `conexiones_con_otras_asignaturas`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.conexiones_con_otras_asignaturas}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`conexiones_con_otras_asignaturas`)}
          </div>
        )}
        {(data.conexiones_con_vida_diaria !== "") && (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full" >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `conexiones_con_vida_diaria` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView("", 'conexiones_con_vida_diaria')}
                <div className={`flex flex-row gap-0 bg-white-A700 w-full rounded-tr-lg rounded-br-lg overlay ${isClicked === `conexiones_con_vida_diaria` ? 'px-2 overlay-active' : ''}`}>
                  <div className="flex flex-1 flex-col font-nunito gap-1 items-start justify-start w-full my-2">
                    <Text className="w-full font-semibold ">
                    {(idSubject === 4) ? "Connections with daily life" : "Conexiones con la vida diaria"}
                    </Text>
                    <div className={`flex flex-row gap-2.5 ${isClicked === `conexiones_con_vida_diaria` ? 'items-center' : 'items-start'} justify-start w-full`}>
                      <div className={`edit-border ${isClicked === `conexiones_con_vida_diaria` ? 'bg-gray-50border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start w-full`}>
                        {(isClicked === `conexiones_con_vida_diaria`) && (
                          <>
                            <div className="font-bold text-gray-900 w-full">
                              <TextArea
                                name={`conexiones_con_vida_diaria`}
                                id={`conexiones_con_vida_diaria`}
                                defaultValue={data.conexiones_con_vida_diaria}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `conexiones_con_vida_diaria`) ? false : true }
                              />
                            </div>
                          </>
                        ) || (
                        <div className="flex flex-col gap-1 mt-2 text-justify items-center justify-start w-full">
                          <Text className="w-full">
                            {data.conexiones_con_vida_diaria}
                          </Text>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(`conexiones_con_vida_diaria`)}
          </div>
        )}
        {((data.instrucciones && data.instrucciones.length > 0) && (
          <Text className="w-full mt-4 font-semibold max-w-[824px]">
            {(idSubject === 4) ? "Instructions" : "Instrucciones"}
          </Text>
        ))}
        {((data.instrucciones && data.instrucciones.length > 0) && data.instrucciones.map((key, index) => (
          <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
            key={index}
          >
            <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${index}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                {habilidadView(key.habilidad, index)}
                <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${index}` ? 'overlay-active' : ''}`}>
                  <Text
                    className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                    size="txtInterBold12"
                  >
                    {index + 1}
                  </Text>
                  <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                    <div className={`flex flex-row gap-2.5 ${isClicked === `${index}` ? 'items-center' : 'items-start'} justify-start w-full px-2`}>
                      <div className={`edit-border ${isClicked === `${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                          {(isClicked === `${index}`) && (
                            <>
                              <div className="max-w-[678px] font-bold text-gray-900 w-full">
                                <TextArea
                                  name={`titulopregunta${index}`}
                                  id={`titulopregunta${index}`}
                                  defaultValue={key.titulo}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `${index}`) ? false : true }
                                />
                              </div>
                              <div className="max-w-[678px] text-gray-900 w-full">
                                <TextArea
                                  name={`enunciadopregunta${index}`}
                                  id={`enunciadopregunta${index}`}
                                  defaultValue={key.detalle}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `${index}`) ? false : true }
                                />
                              </div>
                            </>
                          ) || (
                            <>
                              <div
                                className="max-w-[678px] font-bold text-gray-900 w-full"
                                size="txtNunitoBold17Gray900"
                              >
                                <Latex enunciado={key.titulo} />
                              </div>
                              <div
                                className="max-w-[678px] text-gray-900 w-full"
                                size="txtNunitoBold17Gray900"
                              >
                                <Latex enunciado={key.detalle} />
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {buttonsView(index)}
          </div>
        )) || (CustomSkeleton.loadingOtherCount(3)))}
        {((data.ejemplos && data.ejemplos.length > 0) && (
          <Text className="w-full mt-4 font-semibold max-w-[824px]">
            {(idSubject === 4) ? "Examples" : "Ejemplos"}
          </Text>
        ))}
          <div className="flex flex-col gap-4 items-start justify-start max-w-[824px] w-full">
        {((data.ejemplos && data.ejemplos.length > 0) && data.ejemplos.map((key, index) => (
            <div className="flex flex-row items-start justify-start max-w-[824px] w-full"
            key={index}>
              <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition border-hidden animation-unpaint max-w-[800px] rounded-lg w-full`}>
                <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg items-center`}>
                  <Text
                    className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5"
                    size="txtInterBold12"
                  >
                    {index + 1}
                  </Text>
                  <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full">
                    <div className={`flex flex-row items-start justify-start w-full px-2`}>
                      <div className={`edit-border flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                        <>
                          <div
                            className="max-w-[678px] text-[17px] text-gray-900 w-full"
                            size="txtNunitoBold17Gray900"
                          >
                            <b><Latex enunciado={`${key.titulo_ejemplo}` || ""} /></b>
                            <Latex enunciado={key.detalle_ejemplo || ""} />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )))}
          </div>
      </div>
      )
    )

  const output = (
    <>
      {contentActivities}
    </>
  );

  return output;
}

export { Activities };
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '../ErrorMessage';

const CustomForm = React.forwardRef(
  (
    {
      children,
      onSubmit,
      formData,
      setFormData,
      setIsLoading,
      onSelectChange,
      errors = {},
      ...restProps
    },
    ref,
  ) => {

    useEffect(() => {
      setFormData(formData);
    }, [formData, setFormData]);

    const handleChange = (event) => {
      if (event.target) { 
        setFormData({
          ...formData,
          [event.target.name]: event.target.value
        });
      }
    };

    const handleSelectChange = (name, selectedOptions) => {
      if (onSelectChange) {
        onSelectChange(name, selectedOptions);
      } else {
        const newValue = Array.isArray(selectedOptions)
          ? selectedOptions.map(option => ({ value: option.value, label: option.label }))
          : selectedOptions
          ? { value: selectedOptions.value, label: selectedOptions.label }
          : null;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: newValue
        }));
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsLoading(true);
      onSubmit(formData);
    };

    const processChildren = (childElements) => {
      return React.Children.map(childElements, child => {
        if (!React.isValidElement(child)) {
          return child;
        }

        let childProps = {};
        if (child.props.isSelect) {
          childProps = {
            onChange: (selectedOptions) => handleSelectChange(child.props.name, selectedOptions)
          };
        } else if(child.type === 'input') {
          childProps = {
            onChange: handleChange,
            value: formData[child.props.name] || '',
          };
        }
    
        if (child.props.children) {
          childProps.children = processChildren(child.props.children);
        }
    
        return React.cloneElement(child, childProps);
      });
    };

    return (
      <form onSubmit={handleSubmit} {...restProps}>
        {processChildren(children)}
        <ErrorMessage errors={errors} />
      </form>
    );
  },
);

CustomForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func,
  errors: PropTypes.array,
};

CustomForm.defaultProps = {
  errors: [],
};

export { CustomForm };
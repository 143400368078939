import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { IoEllipsisVertical } from "react-icons/io5";

const LibraryBoxMenu = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      handleClickSee,
      handleClickDelete,
      ...restProps
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const eventDelete = () => {
      handleClose();
      handleClickDelete();
    };

    const eventSee = () => {
      handleClose();
      handleClickSee();
    }

    return (
      <div className={`${wrapClassName}`}>
        <IconButton
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={className}
        >
          <IoEllipsisVertical size={15} className="text-black-900 cursor-pointer" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={eventSee}>Ver / descargar</MenuItem>
          <MenuItem onClick={eventDelete}>Eliminar</MenuItem>
        </Menu>
      </div>
    );
  }
);

export { LibraryBoxMenu };
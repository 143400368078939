import React, { useState } from 'react';
import { Button, Img, Line } from 'components';

function WritingLines({defaultLines = 5, color = 'bg-blue_gray-200', editing = false}) {
  const [lines, setLines] = useState(Array(defaultLines).fill().map((_, i) => <Line key={i} className={`${color} h-0.5 rounded-[1px] w-full`} />));

  const addLine = () => {
    if (lines.length < 10) {
        setLines([...lines, <Line key={lines.length} className={`${color} h-0.5 rounded-[1px] w-full`} />]);
    }
  };

  const removeLine = () => {
    if (lines.length > 1) {
      setLines(lines.slice(0, -1));
    }
  };

  return (
    <div className="flex flex-col gap-6 items-start justify-start max-w-[696px] py-6 w-full">
      {lines}
      {(editing === true) && (
        <div className="flex flex-row items-end justify-start w-auto">
          <Button
            className="flex h-5 items-center justify-center rounded-lg w-5"
            shape="round"
            variant="fill"
            onClick={addLine}
          >
            <Img src="/images/img_lightbulb.svg" />
          </Button>
          <Button
            className="flex h-5 items-center justify-center rounded-lg w-5"
            shape="round"
            variant="fill"
            onClick={removeLine}
          >
            <Img src="/images/img_arrowupshort.svg" />
          </Button>
        </div>
      )}
    </div>
  );
}

export { WritingLines };
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from './utils';

export async function checkTokenValidity() {
  try {
    const response = await fetchData('auth/verify');
    if (response.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error al verificar el token:', error);
    return false;
  }
}

function WithAuth({ children }) {
  let navigate = useNavigate();
  const [isValid, setIsValid] = useState(null);
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    if (accessToken) {
      checkTokenValidity().then(setIsValid);
    } else {
      setIsValid(false);
    }
  }, [accessToken]);

  if (isValid === false) {
    navigate('/');
    return null;
  }

  if (isValid === null) {
    return null;
  }

  return children;
}

export default WithAuth;
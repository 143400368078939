import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { IoCloseCircle } from "react-icons/io5";
import { Button, CustomSkeleton } from 'components'; 

const ImageUpload = ({ className, uploadImage, setUploadImage, setErrors, ...restProps }) => {
    const [imageSize, setImageSize] = useState({ width: 250, height: 250 });
    const [imageLoaded, setImageLoaded] = useState(false);
    const [load, setLoad] = useState(false);
    const [onResize, setOnResize] = useState(false);
    const imageRef = useRef();

    const onDrop = (acceptedFiles) => {
        setLoad(true);
        const reader = new FileReader();
        reader.onload = async () => {
            const img = new Image();
            img.onload = async () => {
                await new Promise((resolve) => {
                    setUploadImage(reader.result);
                    resolve();
                });
                resizeImage(imageSize.width, imageSize.height);
                setLoad(false);
                setErrors(prevErrors => ({ ...prevErrors, image: "" }));
            };
            img.src = reader.result;
        };
        reader.readAsDataURL(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } =  useDropzone({ 
        onDrop, 
        accept: 'image/jpeg, image/png, image/jpg',
        multiple: false,
    });
    const resizeImage = (vWidth, vHeight) => {
        const canvas = document.createElement('canvas');
        canvas.width = vWidth;
        canvas.height = vHeight;
        const ctx = canvas.getContext('2d');
        const img = imageRef.current;
        if (img) {
            ctx.drawImage(img, 0, 0, vWidth, vHeight);
            const dataUrl = canvas.toDataURL('image/jpeg');
            setUploadImage(dataUrl);
        }
    };

    const handleResizeStop = (e, data) => {
        setOnResize(true);
        const newWidth = data.size.width;
        const newHeight = data.size.height;
        setImageSize({ width: newWidth, height: newHeight });
        if (imageLoaded) {
            resizeImage(newWidth, newHeight);
            setOnResize(false);
        }
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className={`w-full h-auto ${className}`} {...restProps} >
            {uploadImage && (
                <div className='flex flex-col'>
                    <div className='w-full text-right'>
                        <Button
                            shape="round"
                            size="xs"
                            className="px-0"
                            onClick={() => {
                                setLoad(false);
                                setUploadImage(null);
                                setErrors(prevErrors => ({ ...prevErrors, image: "" }));
                                setImageLoaded(false);
                            }}
                        >
                            <IoCloseCircle />
                        </Button>
                    </div>
                    <div className={`min-h-[200px] max-h-[301px] border-2 border-dashed border-indigo-A200 bg-gray-50`} >
                        <ResizableBox
                            width={imageSize.width}
                            height={imageSize.height}
                            minConstraints={[100, 100]}
                            maxConstraints={[350, 300]}
                            onResizeStop={handleResizeStop}
                            style={{ position: 'relative', margin: '0 ', border: '1px solid #c2c2c2'}}
                        >
                            <img 
                                ref={imageRef} 
                                src={uploadImage} 
                                alt="Uploaded" 
                                style={{ width: `100%`, height: `100%`, maxWidth: '350px', maxHeight: '300px' }} 
                                onLoad={handleImageLoad}
                            />
                        </ResizableBox>
                    </div>
                </div>
            ) || (
                <div {...getRootProps()} style={{ border: '2px dashed #727cf5', padding: '20px', textAlign: 'center' }}>
                    <input {...getInputProps()} />
                    <p>Arrastra una imagen aquí, o haz clic para seleccionar una</p>
                    { load && <div className='w-5 m-auto'>{CustomSkeleton.iconLoading()}</div> }
                </div>
            )}
        </div>
    );
};

export { ImageUpload };

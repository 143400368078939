import React from 'react';
import { Img, Button, TextArea, Latex } from 'components';
import { IoTrashOutline, IoPencil, IoAddCircleOutline, IoCheckmarkCircleOutline, IoImageOutline } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
  
const userAvatar = localStorage.getItem('avatar_colegio');
const userColegio = localStorage.getItem('colegio');

function Slides ({ data, setData, openModalImage, openModalAdd, openModalDelete, isClicked, setIsClicked, loadingProgress, colorPrimary, colorSecondary, academicYear, subject, slideImage }) {

  const buttonsView = (key, index) => (
    <div className={`edit flex flex-col gap-1 items-center justify-start px-1 py-3 rounded-lg w-auto border-transition bg-transition ${isClicked === `slide${index}` ? 'border-visible bg-indigo-A200' : 'border-hidden animation-unpaint'}`}>
      {(isClicked !== `slide${index}`) && (
        <Button 
          className={`p-1 rounded-lg border border-solid`} 
          shape="round"
          color="white_A700"
          variant="fill"
          disabled={ (loadingProgress === 100) ? false : true }
          onClick={(e) => {
            e.stopPropagation();
            setIsClicked(`slide${index}`);
          }} >
          <IoPencil className={`text-gray-600 ${(loadingProgress === 100) ? 'hover:text-gray-900' : ''} text-xs`} size="20" title="Editar" />
        </Button>
      ) || (
        <Button 
          className={`p-1`} 
          shape="round"
          color="indigo_A200"
          variant="fill"
          onClick={(e) => {
            e.stopPropagation();
            if(key.estructura === "3boxes"){
              const vlor = document.getElementById(`title${index}`).value;
              if (vlor.trim() !== ''){
                data[index].titulo = vlor.trim();
              }

              if (Array.isArray(key.contenido)){
                key.contenido.map((item, ix) => {
                  const vlor2 = document.getElementById(`title${index}${ix}`).value;
                  if (vlor2.trim() !== ''){
                    if (item.hasOwnProperty("title")){
                      data[index].contenido[ix].title = vlor2.trim();
                    }
                  }
                  const vlor3 = document.getElementById(`content${index}${ix}`).value;
                  if (vlor3.trim() !== ''){
                    data[index].contenido[ix].text = vlor3.trim();
                  }
                });
              } else {
                const vlor2 = document.getElementById(`content${index}`).value;
                if (vlor2.trim() !== ''){
                  if (key.contenido.hasOwnProperty("text")){
                    data[index].contenido.text = vlor2.trim();
                  } else {
                    data[index].contenido = vlor2.trim();
                  }
                }
              }
            } else if(key.estructura === "largebulletlist" || key.estructura === "timeline") {
              const vlor = document.getElementById(`title${index}`).value;
              if (vlor.trim() !== ''){
                data[index].titulo = vlor.trim();
              }

              if (Array.isArray(key.contenido)){
                key.contenido.map((item, ix) => {
                  const vlor2 = document.getElementById(`content${index}${ix}`).value;
                  if (vlor2.trim() !== ''){
                    if (item.hasOwnProperty("text")){
                      data[index].contenido[ix].text = vlor2.trim();
                    } else {
                      data[index].contenido[ix] = vlor2.trim();
                    }
                  } else if (ix === key.contenido.length - 1 && ix > 0){
                    data[index].contenido.splice(ix, 1);
                  } else {
                    data[index].contenido[ix] = "";
                  }
                });
                if(key.contenido.length < 4){
                  for (let i = key.contenido.length; i < 4; i++){
                    const vlor2 = document.getElementById(`content${index}${i}`).value;
                    if (vlor2.trim() !== ''){
                      data[index].contenido.push(vlor2.trim());
                    }
                  }
                }
              } else {
                const vlor2 = document.getElementById(`content${index}`).value;
                if (vlor2.trim() !== ''){
                  if (key.contenido.hasOwnProperty("text")){
                    data[index].contenido.text = vlor2.trim();
                  } else {
                    data[index].contenido = vlor2.trim();
                  }
                }
              }
            } else {
              const vlor = document.getElementById(`title${index}`).value;
              if (vlor.trim() !== ''){
                data[index].titulo = vlor.trim();
              }

              if (Array.isArray(key.contenido)){
                key.contenido.map((item, ix) => {
                  const vlor2 = document.getElementById(`content${index}${ix}`).value;
                  if (vlor2.trim() !== ''){
                    if (item.hasOwnProperty("text")){
                      data[index].contenido[ix].text = vlor2.trim();
                    } else {
                      data[index].contenido[ix] = vlor2.trim();
                    }
                  }
                });
              } else {
                const vlor2 = document.getElementById(`content${index}`).value;
                if (vlor2.trim() !== ''){
                  if (key.contenido.hasOwnProperty("text")){
                    data[index].contenido.text = vlor2.trim();
                  } else {
                    data[index].contenido = vlor2.trim();
                  }
                }
              }
            }
            setIsClicked(null);
            setData(prevData => { 
              // Crear una copia del array
              const updatedData = [...prevData];
              updatedData[index] = data[index];
              return updatedData;
            });
          }} >
          <IoCheckmarkCircleOutline className={`text-white-A700 text-xs`} size="20" title="Guardar" />
        </Button>
      )}
      <Button 
        key={`add${index}`}
        className={`edit p-1 ${isClicked === `slide${index}` ? 'fade-in' : 'hidden fade-out'}`}
        shape="round"
        color="indigo_A200"
        variant="fill"
        onClick={() => {
          setIsClicked(null);
          openModalAdd(index);
        }}>
        <IoAddCircleOutline className={`text-white-A700 text-xs`} size="22" title="Agregar" />
      </Button>
      <Button 
        key={`delete${index}`}
        className={`edit p-1 ${isClicked === `slide${index}` ? 'fade-in' : 'hidden fade-out'}`}
        shape="round"
        color="indigo_A200"
        variant="fill"
        onClick={() => {
          setIsClicked(null);
          openModalDelete(index);
        }}>
        <IoTrashOutline className={`text-white-A700 text-xs`} size="22" title="Eliminar" />
      </Button>
    </div>
  );

  const contentSlides = data.map((key, index) => (
    (key.estructura === "accentleft") && (
      <div className="flex flex-row gap-2 items-start justify-start w-full" key={`slide${index}`} id={`slide${index}`}>
        <div className="flex flex-1 flex-row items-start justify-start max-w-[850px] w-full">
          <div className="bg-white-A700 shadow-md w-full min-h-[520px] justify-start items-start flex flex-col">
            <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
              <div className="w-full flex-col justify-start items-center flex">
                <div className="w-full justify-between items-center inline-flex">
                  <div className="w-full h-[84px] relative">
                    <div className="w-[90%] h-[25px] object-left-top absolute rounded-br-[100px]" style={{backgroundColor: colorPrimary}} />
                    <div className="w-[13%] h-full object-left-top absolute rounded-br-[50px]" style={{backgroundColor: colorSecondary}} />
                  </div>
                  <div className="px-5 justify-end items-start gap-2 flex">
                    <div className="flex-col justify-end items-end inline-flex">
                      <div className="text-zinc-600 text-justify text-xs font-normal font-['Nunito']">{academicYear} - {subject}</div>
                      <div className="text-gray-400 text-[8px] font-normal font-['Nunito']">{userColegio || 'Liceo / Colegio'}</div>
                    </div>
                    <div className="justify-start items-start flex">
                      <Img className="w-[48px] h-[33px]" src={userAvatar || "/images/img_genericlogo.png"} />
                    </div>
                  </div>
                </div>
                <div className="w-[80%] justify-center items-center gap-4 flex flex-row h-[380px]">
                  <div className="flex items-start justify-center w-full h-full">
                    {(key.imagen !== "" && key.imagen_url) && (  
                      <Img
                        id={`imagen${index}`}
                        className="w-[300px] h-auto max-h-[380px] object-cover object-center rounded-lg"
                        src={`${slideImage.find(imgr => imgr.id === key.imagen_url)?.img || ""}`}
                      />
                    ) || (
                      <div className="flex items-center justify-center w-full h-full" style={{ backgroundColor: colorSecondary }}>
                        <IoImageOutline className="text-gray-500 m-auto justify-between text-[35px]" />
                      </div>
                    )}
                    <div className="flex flex-row h-5 items-center justify-center">
                      <Button 
                        className={`edit ${isClicked === `slide${index}` ? '' : 'hidden'}`} 
                        shape="round"
                        color="white_A700"
                        variant="fill"
                        onClick={() => {
                          setIsClicked(null);
                          openModalImage(index);
                        }}>
                        <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `slide${index}` ? '' : 'hidden'}`}/>
                      </Button>
                    </div>
                  </div>
                  <div className="w-full h-full">
                    {(isClicked === `slide${index}`) && (
                      <div className={`max-w-[678px] font-bold text-gray-900 w-full mb-5 edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                        <TextArea
                          name={`title${index}`}
                          id={`title${index}`}
                          defaultValue={key.titulo || ''}
                          placeholder=""
                          className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full text-[35px] font-bold font-['Nunito'] leading-10` }
                          wrapClassName={`w-full bg-gray-50`}
                          size="xxs"
                          disabled={ (isClicked === `slide${index}`) ? false : true }
                        />
                      </div>
                    ) || (
                      <div className="self-stretch text-black text-[35px] font-bold font-['Nunito']">{key.titulo}</div>
                    )}
                    {(Array.isArray(key.contenido)) && (  
                      key.contenido.map((item, ix) => (
                        (isClicked === `slide${index}`) && (
                          <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`} key={ix}>
                            <TextArea
                              name={`content${index}${ix}`}
                              id={`content${index}${ix}`}
                              defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                              placeholder=""
                              className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full bg-gray-50`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </div>
                        ) || (
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                            key={ix}
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(item.hasOwnProperty("text")) ? item.text : item || ''} />
                          </div>
                        )
                      )
                    )) || (
                      (isClicked === `slide${index}`) && (
                        <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                          <TextArea
                            name={`content${index}`}
                            id={`content${index}`}
                            defaultValue={(key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                            placeholder=""
                            className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                            wrapClassName={`w-full bg-gray-50`}
                            size="xxs"
                            disabled={ (isClicked === `slide${index}`) ? false : true }
                          />
                        </div>
                      ) || (
                        <>
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''} />
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full justify-end items-end mt-auto flex flex-col">
              <div className="w-full h-full justify-between items-center inline-flex mt-auto">
                <div className="w-[50%] h-[31px] object-left-bottom rotate-180 rounded-bl-[100px]" style={{backgroundColor: colorPrimary}} />
                <div className="w-[22%] h-[31px] object-right-bottom rounded-tl-[50px]" style={{backgroundColor: colorSecondary}} />
              </div>
            </div>
          </div>
        </div>
        {buttonsView(key, index)}
      </div>
    ) || (key.estructura === "accentright") && (
      <div className="flex flex-row gap-2 items-start justify-start w-full" key={`slide${index}`} id={`slide${index}`}>
        <div className="flex flex-1 flex-row items-start justify-start max-w-[850px] w-full">
          <div className="bg-white-A700 shadow-md w-full min-h-[520px] justify-start items-start flex flex-col">
            <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
              <div className="w-full flex-col justify-start items-center flex">
                <div className="w-full justify-between items-center inline-flex">
                  <div className="w-full h-[84px] relative">
                    <div className="w-[90%] h-[25px] object-left-top absolute rounded-br-[100px]" style={{backgroundColor: colorPrimary}} />
                    <div className="w-[13%] h-full object-left-top absolute rounded-br-[50px]" style={{backgroundColor: colorSecondary}} />
                  </div>
                  <div className="px-5 justify-end items-start gap-2 flex">
                    <div className="flex-col justify-end items-end inline-flex">
                      <div className="text-zinc-600 text-justify text-xs font-normal font-['Nunito']">{academicYear} - {subject}</div>
                      <div className="text-gray-400 text-[8px] font-normal font-['Nunito']">{userColegio || 'Liceo / Colegio'}</div>
                    </div>
                    <div className="justify-start items-start flex">
                      <Img className="w-[48px] h-[33px]" src={userAvatar || "/images/img_genericlogo.png"} />
                    </div>
                  </div>
                </div>
                <div className="w-[80%] justify-center items-start gap-4 flex flex-row h-[380px]">
                  <div className="w-full h-full">
                    {(isClicked === `slide${index}`) && (
                      <div className={`max-w-[678px] font-bold text-gray-900 w-full mb-5 edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                        <TextArea
                          name={`title${index}`}
                          id={`title${index}`}
                          defaultValue={key.titulo || ''}
                          placeholder=""
                          className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[35px] font-['Nunito'] leading-10`}
                          wrapClassName={`w-full bg-gray-50`}
                          size="xxs"
                          disabled={ (isClicked === `slide${index}`) ? false : true }
                        />
                      </div>
                    ) || (
                      <div className="self-stretch text-black text-[35px] font-bold font-['Nunito']">{key.titulo}</div>
                    )}
                    {(Array.isArray(key.contenido)) && (  
                      key.contenido.map((item, ix) => (
                        (isClicked === `slide${index}`) && (
                          <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`} key={ix}>
                            <TextArea
                              name={`content${index}${ix}`}
                              id={`content${index}${ix}`}
                              defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                              placeholder=""
                              className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full bg-gray-50`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </div>
                        ) || (
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                            key={ix}
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(item.hasOwnProperty("text")) ? item.text : item || ''} />
                          </div>
                        )
                      ))
                    ) || (
                      (isClicked === `slide${index}`) && (
                        <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                          <TextArea
                            name={`content${index}`}
                            id={`content${index}`}
                            defaultValue={(key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                            placeholder=""
                            className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                            wrapClassName={`w-full bg-gray-50`}
                            size="xxs"
                            disabled={ (isClicked === `slide${index}`) ? false : true }
                          />
                        </div>
                      ) || (
                        <>
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''} />
                          </div>
                        </>
                      )
                    )}
                  </div>
                  <div className="flex items-start justify-center w-full h-full">
                    {(key.imagen !== "" && key.imagen_url) && (  
                      <Img
                        id={`imagen${index}`}
                        className="w-[300px] h-auto max-h-[380px] object-cover object-center rounded-lg"
                        src={`${slideImage.find(imgr => imgr.id === key.imagen_url)?.img || ""}`}
                      />
                    ) || (
                      <div className="flex items-center justify-center w-full h-full" style={{ backgroundColor: colorSecondary }}>
                        <IoImageOutline className="text-gray-500 m-auto justify-between text-[35px]" />
                      </div>
                    )}
                    <div className="flex flex-row h-5 items-center justify-center">
                      <Button 
                        className={`edit ${isClicked === `slide${index}` ? '' : 'hidden'}`} 
                        shape="round"
                        color="white_A700"
                        variant="fill"
                        onClick={() => {
                          setIsClicked(null);
                          openModalImage(index);
                        }}>
                        <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `slide${index}` ? '' : 'hidden'}`}/>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full justify-end items-end mt-auto flex flex-col">
              <div className="w-full h-full justify-between items-center inline-flex mt-auto">
                <div className="w-[50%] h-[31px] object-left-bottom rotate-180 rounded-bl-[100px]" style={{backgroundColor: colorPrimary}} />
                <div className="w-[22%] h-[31px] object-right-bottom rounded-tl-[50px]" style={{backgroundColor: colorSecondary}} />
              </div>
            </div>
          </div>
        </div>
        {buttonsView(key, index)}
      </div>
    ) || (key.estructura === "reflection") && (
      <div className="flex flex-row gap-2 items-start justify-start w-full" key={`slide${index}`} id={`slide${index}`}>
        <div className="flex flex-1 flex-row items-start justify-start max-w-[850px] w-full">
          <div className="shadow-md w-full min-h-[520px] justify-start items-start flex flex-col" style={{backgroundColor: colorPrimary}}>
            <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
              <div className="w-full flex-col justify-start items-center flex">
                <div className="w-full justify-between items-center inline-flex">
                  <div className="w-full h-[84px] relative">
                  </div>
                  <div className="px-5 justify-end items-start gap-2 flex">
                    <div className="flex-col justify-end items-end inline-flex">
                      <div className="text-white-A700 text-justify text-xs font-normal font-['Nunito']">{academicYear} - {subject}</div>
                      <div className="text-white-A700 text-[8px] font-normal font-['Nunito']">{userColegio || 'Liceo / Colegio'}</div>
                    </div>
                    <div className="justify-start items-start flex">
                      <Img className="w-[48px] h-[33px]" src={userAvatar || "/images/img_genericlogo.png"} />
                    </div>
                  </div>
                </div>
                <div className="w-[80%] justify-center items-start gap-4 flex flex-row h-[380px]">
                  <div className="w-full h-full rounded-3xl p-6" style={{backgroundColor: colorSecondary}}>
                    {(isClicked === `slide${index}`) && (
                      <div className={`max-w-[678px] font-bold text-gray-900 w-full mb-5 edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                        <TextArea
                          name={`title${index}`}
                          id={`title${index}`}
                          defaultValue={key.titulo || ''}
                          placeholder=""
                          className={`bg-transparent text-base text-left text-gray-900 p-0 w-full font-bold text-[35px] font-['Nunito'] leading-10`}
                          wrapClassName={`w-full bg-transparent`}
                          size="xxs"
                          disabled={ (isClicked === `slide${index}`) ? false : true }
                        />
                      </div>
                    ) || (
                      <div className="self-stretch text-black text-[35px] font-bold font-['Nunito']">{key.titulo}</div>
                    )}
                    {(Array.isArray(key.contenido)) && (  
                      key.contenido.map((item, ix) => (
                        (isClicked === `slide${index}`) && (
                          <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`} key={ix}>
                            <TextArea
                              name={`content${index}${ix}`}
                              id={`content${index}${ix}`}
                              defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                              placeholder=""
                              className={`bg-transparent text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full bg-transparent`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </div>
                        ) || (
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                            key={ix}
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(item.hasOwnProperty("text")) ? item.text : item || ''} />
                          </div>
                        )
                      ))
                    ) || (
                      (isClicked === `slide${index}`) && (
                        <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                          <TextArea
                            name={`content${index}`}
                            id={`content${index}`}
                            defaultValue={(key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                            placeholder=""
                            className={`bg-transparent text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                            wrapClassName={`w-full bg-transparent`}
                            size="xxs"
                            disabled={ (isClicked === `slide${index}`) ? false : true }
                          />
                        </div>
                      ) || (
                        <>
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                          >
                            <Latex className="self-stretch text-black text-base font-normal font-['Nunito']" enunciado={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''} />
                          </div>
                        </>
                      )
                    )}
                  </div>
                  <div className="flex items-start justify-center w-full h-full">
                    {(key.imagen !== "" && key.imagen_url) && (  
                      <Img
                        id={`imagen${index}`}
                        className="w-[300px] h-auto max-h-[380px] object-cover object-center rounded-lg"
                        src={`${slideImage.find(imgr => imgr.id === key.imagen_url)?.img || ""}`}
                      />
                    ) || (
                      <div className="flex items-center justify-center w-full h-full" style={{ backgroundColor: colorSecondary }}>
                        <IoImageOutline className="text-gray-500 m-auto justify-between text-[35px]" />
                      </div>
                    )}
                    <div className="flex flex-row h-5 items-center justify-center">
                      <Button 
                        className={`edit ${isClicked === `slide${index}` ? '' : 'hidden'}`} 
                        shape="round"
                        color="transparent"
                        variant="fill"
                        onClick={() => {
                          setIsClicked(null);
                          openModalImage(index);
                        }}>
                        <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `slide${index}` ? '' : 'hidden'}`}/>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full justify-end items-end mt-auto flex flex-col">
              <div className="w-full h-full justify-between items-center inline-flex mt-auto">
              </div>
            </div>
          </div>
        </div>
        {buttonsView(key, index)}
      </div>
    ) || (key.estructura === "welcome") && (
      <div className="flex flex-row gap-2 items-start justify-start w-full" key={`slide${index}`} id={`slide${index}`}>
        <div className="flex flex-1 flex-row items-start justify-start max-w-[850px] w-full">
          <div className="shadow-md w-full min-h-[520px] justify-start items-start flex flex-col" style={{backgroundColor: colorPrimary}}>
            <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
              <div className="w-full flex-col justify-start items-center flex">
                <div className="w-full justify-between items-center inline-flex">
                  <div className="w-full h-[84px] relative">
                  </div>
                  <div className="px-5 justify-end items-start gap-2 flex">
                    <div className="flex-col justify-end items-end inline-flex">
                      <div className="text-white-A700 text-justify text-xs font-normal font-['Nunito']">{academicYear} - {subject}</div>
                      <div className="text-white-A700 text-[8px] font-normal font-['Nunito']">{userColegio || 'Liceo / Colegio'}</div>
                    </div>
                    <div className="justify-start items-start flex">
                      <Img className="w-[48px] h-[33px]" src={userAvatar || "/images/img_genericlogo.png"} />
                    </div>
                  </div>
                </div>
                <div className="w-[80%] min-h-[350px] flex-col justify-center items-center gap-4 flex p-6 rounded-3xl" style={{backgroundColor: colorSecondary}}>
                  {(isClicked === `slide${index}`) && (
                    <div className={`max-w-[678px] font-bold text-gray-900 w-full mb-5 edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                      <TextArea
                        name={`title${index}`}
                        id={`title${index}`}
                        defaultValue={key.titulo || ''}
                        placeholder=""
                        className={`bg-transparent text-center p-0 w-full text-gray-900 text-[35px] font-bold font-['Nunito'] leading-10`}
                        wrapClassName={`w-full bg-transparent`}
                        size="xxs"
                        disabled={ (isClicked === `slide${index}`) ? false : true }
                      />
                    </div>
                  ) || (
                    <div className="self-stretch text-center text-black text-[35px] font-bold font-['Nunito']">{key.titulo}</div>
                  )}
                    {(Array.isArray(key.contenido)) && (  
                      key.contenido.map((item, ix) => (
                        (isClicked === `slide${index}`) && (
                          <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                            <TextArea
                              name={`content${index}${ix}`}
                              id={`content${index}${ix}`}
                              defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                              placeholder=""
                              className={`text-base text-center text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full bg-transparent`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </div>
                        ) || (
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                          >
                            <div className="self-stretch text-black text-base font-normal font-['Nunito'] text-center" key={ix}>{(item.hasOwnProperty("text")) ? item.text : item || ''}</div>
                          </div>
                        )
                      ))
                    ) || (
                      (isClicked === `slide${index}`) && (
                        <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                          <TextArea
                            name={`content${index}`}
                            id={`content${index}`}
                            defaultValue={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                            placeholder=""
                            className={`bg-transparent text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                            wrapClassName={`w-full bg-transparent`}
                            size="xxs"
                            disabled={ (isClicked === `slide${index}`) ? false : true }
                          />
                        </div>
                      ) || (
                        <div
                          className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-transparent border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                          size="txtNunitoBold17Gray900"
                        >
                          <div className="self-stretch text-black text-base font-normal font-['Nunito']">{(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}</div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="w-full justify-end items-end mt-auto flex flex-col">
              <div className="w-full h-full justify-between items-center inline-flex mt-auto">
              </div>
            </div>
          </div>
        </div>
        {buttonsView(key, index)}
      </div>
    ) || (
      <div className="flex flex-row gap-2 items-start justify-start w-full" key={`slide${index}`} id={`slide${index}`}>
        <div className="flex flex-1 flex-row items-start justify-start max-w-[850px] w-full">
          <div className="bg-white-A700 shadow-md w-full min-h-[520px] justify-start items-start flex flex-col">
            <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
              <div className="w-full flex-col justify-start items-center flex">
                <div className="w-full justify-between items-center inline-flex">
                  <div className="w-full h-[84px] relative">
                    <div className="w-[90%] h-[25px] object-left-top absolute rounded-br-[100px]" style={{backgroundColor: colorPrimary}} />
                    <div className="w-[13%] h-full object-left-top absolute rounded-br-[50px]" style={{backgroundColor: colorSecondary}} />
                  </div>
                  <div className="px-5 justify-end items-start gap-2 flex">
                    <div className="flex-col justify-end items-end inline-flex">
                      <div className="text-zinc-600 text-justify text-xs font-normal font-['Nunito']">{academicYear} - {subject}</div>
                      <div className="text-gray-400 text-[8px] font-normal font-['Nunito']">{userColegio || 'Liceo / Colegio'}</div>
                    </div>
                    <div className="justify-start items-start flex">
                      <Img className="w-[48px] h-[33px]" src={userAvatar || "/images/img_genericlogo.png"} />
                    </div>
                  </div>
                </div>
                <div className="w-[80%] min-h-[350px] flex-col justify-center items-center gap-4 flex">
                  {(isClicked === `slide${index}`) && (
                    <div className={`max-w-[678px] font-bold text-gray-900 w-full mb-5 edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                      <TextArea
                        name={`title${index}`}
                        id={`title${index}`}
                        defaultValue={key.titulo || ''}
                        placeholder=""
                        className={`bg-gray-50 text-left p-0 w-full text-gray-900 text-[35px] font-bold font-['Nunito'] leading-10`}
                        wrapClassName={`w-full bg-gray-50`}
                        size="xxs"
                        disabled={ (isClicked === `slide${index}`) ? false : true }
                      />
                    </div>
                  ) || (
                    <div className="self-stretch text-black text-[35px] font-bold font-['Nunito']">{key.titulo}</div>
                  )}
                  {(key.estructura === "titlewithtext") && (
                    (Array.isArray(key.contenido)) && (  
                      key.contenido.map((item, ix) => (
                        (isClicked === `slide${index}`) && (
                          <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                            <TextArea
                              name={`content${index}${ix}`}
                              id={`content${index}${ix}`}
                              defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                              placeholder=""
                              className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full bg-gray-50`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </div>
                        ) || (
                          <div
                            className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                            size="txtNunitoBold17Gray900"
                          >
                            <div className="self-stretch text-black text-base font-normal font-['Nunito']" key={ix}>{(item.hasOwnProperty("text")) ? item.text : item || ''}</div>
                          </div>
                        )
                      ))
                    ) || (
                      (isClicked === `slide${index}`) && (
                        <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                          <TextArea
                            name={`content${index}`}
                            id={`content${index}`}
                            defaultValue={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                            placeholder=""
                            className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                            wrapClassName={`w-full bg-gray-50`}
                            size="xxs"
                            disabled={ (isClicked === `slide${index}`) ? false : true }
                          />
                        </div>
                      ) || (
                        <div
                          className={`max-w-[678px] text-[17px] font-normal text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                          size="txtNunitoBold17Gray900"
                        >
                          <div className="self-stretch text-black text-base font-normal font-['Nunito']">{(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}</div>
                        </div>
                      )
                    )
                  ) || (key.estructura === "timeline") && (
                    <div className="w-full flex flex-col gap-2">
                      {(Array.isArray(key.contenido)) && (  
                        key.contenido.map((item, ix) => (
                          (ix < 4) && (
                          (isClicked === `slide${index}`) && (
                            <div className="justify-start items-center w-full h-full flex flex-row gap-2" key={ix}>
                              <div className="w-[50px] h-[50px] px-5 rounded-lg text-white-A700 flex items-center justify-center" style={{backgroundColor: (ix % 2 === 0) ? colorPrimary : colorSecondary}}><span className="align-middle text-center">{ix+1}</span></div>
                              <div className="w-[30px] h-[30px] rounded-lg text-white-A700 flex items-center justify-center border border-blue_gray-300 p-1"><BsArrowRight className="text-[25px] text-blue_gray-200_01" /></div>
                              <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                                <TextArea
                                  name={`content${index}${ix}`}
                                  id={`content${index}${ix}`}
                                  defaultValue={(item.hasOwnProperty("text")) ? item.text : item|| ''}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `slide${index}`) ? false : true }
                                />
                              </div>
                            </div>
                          ) || (
                            <div className="justify-start items-center w-full h-full flex flex-row gap-2" key={ix}>
                              <div className="w-[50px] h-[50px] px-5 rounded-lg text-white-A700 flex items-center justify-center" style={{backgroundColor: (ix % 2 === 0) ? colorPrimary : colorSecondary}}><span className="align-middle text-center">{ix+1}</span></div>
                              <div className="w-[30px] h-[30px] rounded-lg text-white-A700 flex items-center justify-center border border-blue_gray-300 p-1"><BsArrowRight className="text-[25px] text-blue_gray-200_01" /></div>
                              <div className="self-stretch text-black text-base font-normal text-[17px] font-['Nunito']">{(item.hasOwnProperty("text")) ? item.text : item || ''}</div>
                            </div>
                          )) || (
                            key.contenido.splice(ix, 1)
                          )
                        ))
                      )}
                      {((Array.isArray(key.contenido)) && (isClicked === `slide${index}`) && (key.contenido.length < 4) && (
                          Array.from({length: 4 - key.contenido.length}).map((_, i) => (
                            <div className="justify-start items-center w-full h-full flex flex-row gap-2" key={i + key.contenido.length}>
                              <div className="w-[50px] h-[50px] px-5 rounded-lg text-white-A700 flex items-center justify-center" style={{backgroundColor: (i + key.contenido.length  % 2 === 0) ? colorPrimary : colorSecondary}}><span className="align-middle text-center">{i + key.contenido.length + 1}</span></div>
                              <div className="w-[30px] h-[30px] rounded-lg text-white-A700 flex items-center justify-center border border-blue_gray-300 p-1"><BsArrowRight className="text-[25px] text-blue_gray-200_01" /></div>
                              <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                                <TextArea
                                  name={`content${index}${i + key.contenido.length}`}
                                  id={`content${index}${i + key.contenido.length}`}
                                  defaultValue=""
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `slide${index}`) ? false : true }
                                />
                              </div>
                            </div>
                          ))
                        )
                      )}
                      {(!Array.isArray(key.contenido)) && ((isClicked === `slide${index}`) && (
                            <div className="justify-start items-center w-full h-full flex flex-row gap-2">
                              <div className="w-[50px] h-[50px] px-5 rounded-lg text-white-A700 flex items-center justify-center" style={{backgroundColor: colorPrimary}}><span className="align-middle text-center">1</span></div>
                              <div className="w-[30px] h-[30px] rounded-lg text-white-A700 flex items-center justify-center border border-blue_gray-300 p-1"><BsArrowRight className="text-[25px] text-blue_gray-200_01" /></div>
                              <div className={`max-w-[678px] font-bold text-gray-900 w-full edit-border ${isClicked === `slide${index}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}>
                                <TextArea
                                  name={`content${index}`}
                                  id={`content${index}`}
                                  defaultValue={(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `slide${index}`) ? false : true }
                                />
                              </div>
                            </div>
                          ) || (
                            <div className="justify-start items-center w-full h-full flex flex-row gap-4">
                              <div className="w-[50px] h-[50px] px-5 rounded-lg text-white-A700 flex items-center justify-center" style={{backgroundColor: colorPrimary}}><span className="align-middle text-center">1</span></div>
                              <div className="w-[30px] h-[30px] rounded-lg text-white-A700 flex items-center justify-center border border-blue_gray-300 p-1"><BsArrowRight className="text-[25px] text-blue_gray-200_01" /></div>
                              <div className="self-stretch text-black text-base font-normal text-[17px] font-['Nunito']">{(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}</div>
                            </div>
                          ))
                      }
                    </div>
                  ) || (key.estructura === "largebulletlist") && (
                    <ul className="w-full grid grid-cols-2 gap-5 justify-start items-end sm:gap-3 list-disc">
                      {(Array.isArray(key.contenido)) && (  
                        key.contenido.map((item, ix) => (
                          (isClicked === `slide${index}`) && (
                            <li className={`self-stretch text-black text-base font-normal font-['Nunito'] rounded-lg text-white-A700 p-2 `} style={{backgroundColor: (ix === 0 || ix === 3) ? colorPrimary : colorSecondary}} key={ix}>
                              <TextArea
                                name={`content${index}${ix}`}
                                id={`content${index}${ix}`}
                                defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                                placeholder=""
                                color="transparent"
                                className={`text-base text-left text-white_A700 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                                wrapClassName={`w-full edit-border ${isClicked === `slide${index}` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : 'bg-gray-50'}`}
                                size="xxs"
                                disabled={ (isClicked === `slide${index}`) ? false : true }
                              />
                            </li>
                          ) || (
                            <li className="self-stretch text-black text-base font-normal text-[17px] font-['Nunito'] rounded-lg text-white-A700 p-2" style={{backgroundColor: (ix === 0 || ix === 3) ? colorPrimary : colorSecondary}} key={ix}>{(item.hasOwnProperty("text")) ? item.text : item || ''}</li>
                          )
                        ))
                      )}
                      {((Array.isArray(key.contenido)) && (isClicked === `slide${index}`) && (key.contenido.length < 4) && (
                          Array.from({length: 4 - key.contenido.length}).map((_, i) => (
                            <li className={`self-stretch text-black text-base font-normal font-['Nunito'] rounded-lg text-white-A700 p-2 `} style={{backgroundColor: (i + key.contenido.length === 0 || i + key.contenido.length === 3) ? colorPrimary : colorSecondary}} key={i + key.contenido.length}>
                              <TextArea
                                name={`content${index}${i + key.contenido.length}`}
                                id={`content${index}${i + key.contenido.length}`}
                                defaultValue=""
                                placeholder=""
                                color="transparent"
                                className={`text-base text-left text-white_A700 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                                wrapClassName={`w-full edit-border ${isClicked === `slide${index}` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : 'bg-gray-50'}`}
                                size="xxs"
                                disabled={ (isClicked === `slide${index}`) ? false : true }
                              />
                            </li>
                          ))
                        )
                      )}
                      {(!Array.isArray(key.contenido)) && ((isClicked === `slide${index}`) && (
                          <li className={`self-stretch text-black text-base font-normal font-['Nunito'] rounded-lg text-white-A700 p-2 `} style={{backgroundColor: colorPrimary}}>
                            <TextArea
                              name={`content${index}`}
                              id={`content${index}`}
                              defaultValue={key.contenido}
                              placeholder=""
                              className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-normal text-[17px] font-['Nunito']`}
                              wrapClassName={`w-full edit-border ${isClicked === `slide${index}` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : 'bg-gray-50'}`}
                              size="xxs"
                              disabled={ (isClicked === `slide${index}`) ? false : true }
                            />
                          </li>
                        ) || (
                          <li className="self-stretch text-black text-base font-normal text-[17px] font-['Nunito'] rounded-lg text-white-A700 p-2" style={{backgroundColor: colorPrimary}}>{key.contenido}</li>
                        ))}
                    </ul>
                  ) || (key.estructura === "3boxes") && (
                    <div className="w-full grid grid-cols-3 gap-5 justify-start items-start sm:gap-3">
                      {(Array.isArray(key.contenido)) && (  
                        key.contenido.map((item, ix) => (
                          (isClicked === `slide${index}`) && (
                            <div className="justify-center items-start flex" key={ix}>
                              <div className="justify-start items-start gap-4 flex">
                                <div className="w-full min-h-[225px] p-4 sm:p-2 rounded-lg flex-col justify-start items-start gap-2 inline-flex" style={{backgroundColor: (ix % 2 === 0) ? colorPrimary : colorSecondary}}>
                                  <div className="self-stretch p-1 bg-gray-50 rounded-lg justify-start items-start inline-flex">
                                    <div className="grow shrink basis-0 text-black text-base font-bold font-['Nunito'] sm:text-[10px]">
                                      <TextArea
                                        name={`title${index}${ix}`}
                                        id={`title${index}${ix}`}
                                        defaultValue={item.title || ''}
                                        placeholder=""
                                        className={`text-base text-left text-gray-900 p-0 w-full font-bold sm:text-[10px] text-[17px] font-['Nunito']`}
                                        wrapClassName={`w-full edit-border ${isClicked === `slide${index}` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                                        size="xxs"
                                        disabled={ (isClicked === `slide${index}`) ? false : true }
                                      />
                                    </div>
                                  </div>
                                  <div className="self-stretch justify-start items-start inline-flex">
                                    <div className="grow shrink basis-0 text-black text-xs font-semibold font-['Nunito'] sm:text-[10px] text-white-A700">
                                      <TextArea
                                        name={`content${index}${ix}`}
                                        id={`content${index}${ix}`}
                                        defaultValue={(item.hasOwnProperty("text")) ? item.text : item || ''}
                                        placeholder=""
                                        color="transparent"
                                        className={`text-xs text-left text-white_A700 p-0 w-full font-normal sm:text-[10px] text-[17px] font-['Nunito'] leading-5`}
                                        wrapClassName={`w-full edit-border ${isClicked === `slide${index}` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                                        size="xxs"
                                        disabled={ (isClicked === `slide${index}`) ? false : true }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) || (
                            <div className="justify-center items-start flex" key={ix}>
                              <div className="justify-start items-start gap-4 flex w-full">
                                <div className="w-full min-h-[225px] p-4 sm:p-2 rounded-lg flex-col justify-start items-start gap-2 inline-flex" style={{backgroundColor: (ix % 2 === 0) ? colorPrimary : colorSecondary}}>
                                  <div className="self-stretch p-1 bg-gray-50 rounded-lg justify-start items-start inline-flex">
                                    <div className="grow shrink basis-0 text-black text-base font-bold font-['Nunito'] sm:text-[10px] text-[17px]">{item.title || ''}</div>
                                  </div>
                                  <div className="self-stretch justify-start items-start inline-flex">
                                    <div className="grow shrink basis-0 text-black text-xs font-normal font-['Nunito'] leading-5 sm:text-[10px] text-[17px] text-white-A700">{(item.hasOwnProperty("text")) ? item.text : item || ''}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        ))
                      ) || (
                        <div className="justify-center items-start flex">
                          <div className="justify-start items-start gap-4 flex">
                            <div className="w-full p-4 sm:p-2 rounded-lg flex-col justify-start items-start gap-2 inline-flex" style={{backgroundColor: colorPrimary}}>
                              <div className="self-stretch justify-start items-start inline-flex">
                                <div className="grow shrink basis-0 text-black text-xs font-semibold font-['Nunito'] sm:text-[10px]">{(key.contenido && key.contenido.hasOwnProperty("text")) ? key.contenido.text : key.contenido || ''}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full justify-end items-end mt-auto flex flex-col">
              <div className="w-full h-full justify-between items-center inline-flex mt-auto">
                <div className="w-[50%] h-[31px] object-left-bottom rotate-180 rounded-bl-[100px]" style={{backgroundColor: colorPrimary}} />
                <div className="w-[22%] h-[31px] object-right-bottom rounded-tl-[50px]" style={{backgroundColor: colorSecondary}} />
              </div>
            </div>
          </div>
        </div>
        {buttonsView(key, index)}
      </div>
    )
  ));

  const output = (
    <>
      {contentSlides}
    </>
  );

  return output;
}

export { Slides };
import React, { useEffect, useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";

const variants = { fill: { white_A700: "bg-white-A700 text-gray-600" } };
const shapes = { round: "rounded-lg", square: "rounded-none", roundFull: "rounded-full" };
const sizes = { xs: "py-1 items-center justify-center px-[7px]" };

const SelectBox = React.forwardRef(
  (
    {
      children,
      placeholder = "Select",
      className = "",
      options = [],
      isSearchable = false,
      isClearable = true,
      placeholderClassName = "",
      isMulti = false,
      isSelect = true,
      onChange = () => {},
      value = null,
      errors = [],
      indicator,
      shape = "round",
      size = "xs",
      variant = "fill",
      color = "white_A700",
      ...restProps
    },
    ref,
  ) => {
    const [selectedVal, setSelectedVal] = useState(value);
    
    useEffect(() => {
      window.addEventListener('error', e => {
        if (e.message.startsWith('ResizeObserver loop')) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
      });
    }, []);

    const handleChange = (selectedOptions) => {
      const newValue = isMulti
        ? selectedOptions.map(option => ({ value: option.value, label: option.label }))
        : selectedOptions ? { value: selectedOptions.value, label: selectedOptions.label } : null;

      setSelectedVal(newValue);
    };

    useEffect(() => {
      handleChange(value);
    }, [value]);

    const handleOnChange = (selectedOptions) => {
      const newValue = isMulti
        ? selectedOptions.map(option => ({ value: option.value, label: option.label }))
        : selectedOptions ? { value: selectedOptions.value, label: selectedOptions.label } : null;

      if (onChange) {
        onChange(newValue);
      }
    };

    const selectedValue = isMulti 
      ? options.filter(option => selectedVal.some(sel => sel.value === option.value))
      : selectedVal ? { value: selectedVal.value, label: selectedVal.label } : null;

    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} ${(shape && shapes[shape]) || ""} ${
            (size && sizes[size]) || ""
          } ${(variant && variants[variant]?.[color]) || ""}`}
          placeholder={
            <div className={placeholderClassName}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          isClearable={isClearable}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          value={selectedValue}
          onChange={handleOnChange}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 0,
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "0 !important",
              minHeight: "auto",
              "&:hover": {
                border: "0 !important",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected && "#fafafa",
              backgroundColor: state.isSelected && "#727cf5",
              "&:hover": { backgroundColor: "#727cf5", color: "#ffffff" },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
              margin: "0",
              padding: "0",
              // height: "0",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingTop: "0px",
              paddingBottom: "0px",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: "0",
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              padding: "0",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            placeholder: (base) => ({
              ...base,
              margin: 0,
            }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === "scrollContainer";
          }}
          {...restProps}
        />
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  },
);

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isSelect: PropTypes.bool,
  shape: PropTypes.string,
  size: PropTypes.oneOf(["xs"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["white_A700"]),
};

export { SelectBox };

import React from 'react';
import './ProgressBar.css'; // Asumiendo que los estilos están en este archivo

const ProgressBar = ({ percentage, color = '727cf5' }) => {
  const style = {
    width: `${percentage}%`,
    backgroundColor: `#${color}`,
    height: '10px', // Altura de la barra
    borderRadius: '5px', // Bordes redondeados
  };

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={style}></div>
    </div>
  );
};

export { ProgressBar };

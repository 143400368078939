import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { Text } from 'components';

const TopBar = ({ datos = [], color = 'indigo-A200', textcolor = 'white-A700', type = "breadcrumbs" }) => {

  return (
    <div className={`flex flex-row gap-2 h-auto items-center justify-start w-full py-2 px-2 text-${textcolor} bg-${color} min-h-[50px]`}>
      <div className="justify-between max-w-[1350px] mx-auto px-[112px] md:px-5 w-full">
        {(type === "breadcrumbs") && (
        <Breadcrumbs separator={`/`} maxItems={4} aria-label="breadcrumb" className={`!text-gray-400`}>
          { datos && datos.length > 0 && (
            <Text
              className={`text-${textcolor} font-bold flex flex-row gap-1 items-center justify-center cursor-pointer font-inter sm:text-[12px]`}
            >
                { datos[0] && datos[0] !== '' && datos[0] }
            </Text>
          )}
          { datos && datos.length > 0 && datos.map((item, index) => (
            (index > 0) && (
              <Text
                key={index}
                className={`text-${textcolor} flex flex-row gap-1 items-center justify-center font-inter sm:text-[12px] !cursor-default`}
              >
                { item }
              </Text>
            )
          ))}
        </Breadcrumbs>
        )}
        {(type === "buttons") && (
        <div className='flex flex-row gap-2 items-center justify-center'>
          { datos && datos.length > 0 && datos.map((item, index) => (
            <div key={index}>{ item }</div>
          ))}
        </div>
        )}
      </div>
    </div>
  );
};

export { TopBar };
import axios from 'axios';
import pako from 'pako';

export const handleSectionNavigation = (id) => {
  const element = document.getElementById(id);
  const offset = 45;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect().top ?? 0;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const fetchData = async (url, method = 'get', body = null, config = {}) => {
  try {
    const accessToken = localStorage.getItem('access_token');
    const authConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let response;
    if (method.toLowerCase() === 'get') {
      response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/${url}`, authConfig);
    } else if (method.toLowerCase() === 'post') {
      response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/${url}`, body, authConfig);
    } else {
      throw new Error('Invalid method');
    }

    if (response.status !== 200 && !response.data.status && response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error(`Error fetching data: ${error}`);
    return { status: false, error: error.message, data: [] };
  }
};

export const JSONtoListHTML = (json) => {
  let html = `<ul style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">`;
  for (let key in json) {
      if (json.hasOwnProperty(key)) {
          html += '<li style="display: flex; justify-content: space-between; align-items: start; margin-top: 10px;">';
          html += '<h5 style="margin: 0; margin-right: 1em; text-align: start;">' + key + ') </h5>';
          if (typeof json[key] === 'object') {
              html += JSONtoListHTML(json[key]); // Llamada recursiva
          } else {
              json[key] = (json[key] === false) ? 'No' : (json[key] === true) ? 'Si' : json[key];
              html += json[key];
          }
          html += '</li>';
      }
  }
  html += '</ul>';
  return html;
};

export const getIMG = async (type, bodyData) => {
  if (!bodyData || (type !== 'guides' && type !== 'activities' && type !== 'presentations')) {
    return "";
  }
  const responseImage = await fetchData(`portal/news/${type}/getimage`, 'post', bodyData);
  if (responseImage.status === true && responseImage.data && responseImage.data.image && responseImage.data.id) {
    return {img: responseImage.data.image, id: responseImage.data.id};
  } else {
    return {img: "", id: ""};
  }
};

export const setIMG = async (type, bodyData) => {
  if (!bodyData || (type !== 'guides' && type !== 'activities' && type !== 'presentations')) {
    return "";
  }
  const responseImage = await fetchData(`portal/news/${type}/setimage`, 'post', bodyData);
  if (responseImage.status === true && responseImage.data && responseImage.data.id) {
    return responseImage.data.id;
  } else {
    return "";
  }
}

export const comprimeBody = (body) => {
  const CHUNK_SIZE = 0x8000; // 32k
  const compressedData = pako.deflate(JSON.stringify(body));
  let base64Data = '';
  const array = new Uint8Array(compressedData);
  for (let i = 0; i < array.length; i += CHUNK_SIZE) {
    const chunk = array.subarray(i, i + CHUNK_SIZE);
    base64Data += btoa(String.fromCharCode.apply(null, chunk));
  }
  return base64Data;
};

export const hslToHex = (h, s, l) => {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // Convert to Hex and ensure 2 digits
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const hexToHsl = (hex) => {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  // 6 digits
  } else if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return {h:parseFloat((h * 360).toFixed(2)), s:parseFloat((s * 100).toFixed(2)), l:parseFloat((l * 100).toFixed(2))};
}
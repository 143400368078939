import React, { useState, useEffect } from "react";
import { Img, Text, Button, CustomSkeleton } from "components";
import { PiUserCircleDuotone } from "react-icons/pi";
import { TbLogout2 } from "react-icons/tb";
import { IoMenu } from "react-icons/io5";
import { checkTokenValidity } from "../WithAuth";
import FloatingWhatsApp from '@harisenin/react-floating-whatsapp';

function LayoutPage(body = '', rightMenu = null, topMenu = null, color = 'bg-white-A700') {
  const [isOpen, setIsOpen] = useState((rightMenu === null) ? false : true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onSession = async () => {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URI}`;
        return null;
      }
      const isValid = await checkTokenValidity(accessToken);
      if (!isValid) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URI}`;
        return null;
      }
      setIsLoading(false);
    };

    onSession();
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const mediaMatcher = window.matchMedia('(max-width: 1500px)');
    const sidebar = document.querySelector('.sidebar');
    let topSidebar;
  
    const checkSidebar = () => {
      if (sidebar) {
        let parent = sidebar.parentElement;
        let parentTop = (topSidebar && topSidebar.offsetHeight) ? topSidebar.offsetHeight : 0
        if (window.pageYOffset > parent.offsetTop) {
          sidebar.style.top = `${parentTop}px`;
        } else {
          sidebar.style.top = `${parent.offsetHeight + parentTop}px`;
        }
      }
      if (topSidebar) {
        let parent = topSidebar.parentElement;
        if (window.pageYOffset > parent.offsetTop) {
          topSidebar.style.top = `0px`;
        } else {
          topSidebar.style.top = `${parent.offsetHeight}px`;
        }
      }
    };
  
    const checkMatch = () => {
      checkSidebar();
      if (mediaMatcher.matches) {
        setTimeout(() => {
          setIsOpen(false);
        }, 1250);
      } else {
        setIsOpen(true);
      }
    };
  
    setTimeout(() => {
      topSidebar = document.querySelector('.topsidebar');
      checkMatch();
      mediaMatcher.addListener(checkMatch);
      window.addEventListener('scroll', checkSidebar);
      window.addEventListener('resize', checkSidebar);
    }, 0);
  
    return () => {
      mediaMatcher.removeListener(checkMatch);
      window.removeEventListener('scroll', checkSidebar);
      window.removeEventListener('resize', checkSidebar);
    };
  }, []);

  const fulllname = localStorage.getItem('fullname') || "Usuario";
  const colegio = localStorage.getItem('colegio') || "Liceo / Colegio";

  const profile = (
    <div className="flex flex-row gap-2.5 items-center justify-center w-auto ">
      <div className="items-center justify-center rounded-lg"><PiUserCircleDuotone className="m-auto text-blue_gray-200 rounded-lg" size={50} /></div>
      <div className="flex flex-col items-start justify-start w-auto">
        <Text
          className="text-base text-gray-700 w-auto"
          size="txtNunitoRegular16"
        >
          {fulllname}
        </Text>
        <Text
          className="text-blue_gray-200 text-xs w-auto"
          size="txtNunitoRegular12"
        >
          {colegio}
        </Text>
      </div>
    </div>
  );

  return (
    <>
      <div className="fixed bottom-1 right-1 !z-[9999999]">
        <FloatingWhatsApp 
          phoneNumber={`${process.env.REACT_APP_PHONE_NUMBER_WHATSAPP}`}
          accountName='Brincus teachers'
          chatMessage="Hola! Cómo podemos ayudarte?"
          statusMessage="Atención al cliente"
          avatar="/images/logoemail.png"
          height={250}
          className="!z-[9999999]"
          placeholder="Escribe tu mensaje"
          allowClickAway
        />
        <label className="text-black-900 font-nunito text-[14px] text-shadow-whatsapp">¿Necesitas ayuda?</label>
      </div>
      <div className={`${color} flex flex-col font-nunito items-center justify-start mx-auto w-full h-screen`}>
        <div className={`bg-white-A700 header flex flex-col items-center justify-start sm:px-5 shadow-bs2 w-full`}>
          <div className="flex flex-row items-center justify-start pt-[30px] pb-[20px] px-[20px] w-full">
            <div className="flex md:flex-col flex-row md:gap-5 items-center justify-between max-w-[1350px] mx-auto px-[112px] md:px-5 w-full min-h-[60px]">
              <div className="flex flex-row gap-2 items-center justify-center">
                <a href="/">
                  <Img
                    className="h-[39px] max-w-[200px]"
                    src="/images/TEACHERS_LONG.svg"
                  />
                </a>
              </div>
              <div className="flex flex-row gap-2 items-center justify-start w-auto">
                {profile}
                <div className={`${(rightMenu === null) ? 'hidden' : '' }`}>
                  <Button
                    className="relative cursor-pointer font-semibold text-base text-center ring-0 ring-gray-100 border duration-200 hover:bg-indigo-A200 rounded-full w-[50px] h-[50px] text-indigo-A100 hover:text-white-A700"
                    size="xs"
                    variant="fill"
                    onClick={toggleOpen}
                    title="Menú"
                  >
                    <IoMenu className="m-auto rounded-lg" size={24} />
                  </Button>
                </div>
                <div className="items-center justify-center rounded-lg">
                  <Button
                    className="relative cursor-pointer font-semibold text-base text-center ring-0 ring-gray-100 border duration-200 hover:bg-pink-300 rounded-full w-[50px] h-[50px] text-indigo-A100 hover:text-white-A700"
                    size="xs"
                    variant="fill"
                    onClick={() => window.location.href='/logout'}
                    title="Cerrar sesión"
                  >
                    <TbLogout2 className="m-auto rounded-lg" size={24} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {(topMenu != null) && (
            <div className="topsidebar z-10 w-full fixed">
              {topMenu}
            </div>
          )}
          {(rightMenu != null) && (
            <div className={`sidebar z-10 bg-gray-50_01 border border-blue_gray-50 border-solid flex flex-col h-full items-end justify-start py-2 px-6 sm:px-5 w-[300px] ${isOpen ? 'open' : 'closed'}`}>
              <div className="flex flex-col items-end justify-end w-full mb-4">
                <button onClick={toggleOpen} className="relative group">
                  <div className="relative flex overflow-hidden text-gray-700 items-center justify-center rounded-full w-[20px] h-[20px] transform transition-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                </button>
              </div>
              {rightMenu}
            </div>
          )}
        </div>
        <div className={`items-center justify-start mx-auto w-full ${color} ${(rightMenu === null) ? 'flex flex-row' : '' }`}>
          <div className={`max-w-[1200px] items-center justify-start mx-auto pt-[30px] font-nunito px-[20px] sm:px-[2px] w-full`}>
            {(isLoading === false) && (body) || (CustomSkeleton.loadingPage())}
          </div>
        </div>
        {/* <div className="font-dmsans items-center justify-start w-full">
          <div className="bg-indigo-A200 grid sm:grid-cols-1 grid-cols-2 gap-2 items-center justify-center px-10 sm:px-5 py-10 w-full">
            <Text
              className="text-lg text-white-A700 w-auto"
              size="txtDMSansRegular18"
            >
              Copyright © 2023 Brincus.com
            </Text>
            <Text
              className="text-lg text-right text-white-A700 w-auto"
              size="txtDMSansRegular18"
            >
              <span className="text-white-A700 font-dmsans font-normal">
                Todos los derechos reservados |{" "}
              </span>
              <a
                href="#"
                className="text-white-A700 font-dmsans font-normal underline"
              >
                Términos y condiciones
              </a>
            </Text>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default LayoutPage;

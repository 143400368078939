import React, { useState } from 'react';
import { Text, Img, Button, List, WritingLines, TextArea, Latex, CustomSkeleton, CheckBox, Input } from 'components';
import { fetchData, comprimeBody } from '../../utils';
import { FaInfo } from "react-icons/fa";
import { IoTrashOutline, IoPencil, IoAddCircleOutline, IoCheckmarkCircleOutline, IoImageOutline, IoCloseCircle } from "react-icons/io5";
import { Tooltip } from '@mui/material';

function Questions ({ types, data, setData, openModalImage, openModalAdd, openModalDelete, isClicked, setIsClicked, loadingProgress, setSelectedType, subject, idSubject, yearsOld, slideImage }) {
  const [isLoading, setIsLoading] = useState(false);
  const habilidadView = (dataH, key) => (
    <div className={`edit w-[24px] rounded-tl-lg flex-2 justify-center ${isClicked === `${key}` ? 'fade-in' : 'fade-out'}`}>
      <Tooltip title={dataH}>
        <div className='h-full'><FaInfo className="text-white-A700 my-3 mx-auto" size="15" /></div>
      </Tooltip>
    </div>
  );

  const buttonsView = (key, key2, index, index2) => (
    <div className={`edit flex flex-col gap-5 items-center justify-start px-1 py-3 rounded-lg w-auto border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'}`}>
      {(isClicked !== `${key}${key2}`) ? (
        <Button 
          className={`p-1 rounded-lg border border-solid`} 
          shape="round"
          color="white_A700"
          variant="fill"
          disabled={ (loadingProgress === 100) ? false : true }
          onClick={(e) => {
            e.stopPropagation();
            setIsClicked(`${key}${key2}`);
            setSelectedType(`${key}`);
          }} >
          <IoPencil className={`text-gray-600 ${(loadingProgress === 100) ? 'hover:text-gray-900' : ''} text-xs`} size="20" title="Editar" />
        </Button>
      ) : (
        <Button 
          className={`p-1`} 
          shape="round"
          color="indigo_A200"
          variant="fill"
          disabled={isLoading}
          onClick={async (e) => {
            e.stopPropagation();
            setIsLoading(true);
            if(key === 'multiple_choice'){
              const vlor = document.getElementById(`enunciadopregunta${key}${key2}`).value;
              if (vlor.trim() !== ''){
                data[key].preguntas[key2].enunciado_individual.enunciado_pregunta = vlor.trim();
              }

              Object.keys(data[key].preguntas[key2].posibles_respuestas.respuestas).map((key3, index3) => {
                const vlor2 = document.getElementById(`respuesta${index}${index2}${index3}`).value;
                if (vlor2.trim() !== ''){
                  data[key].preguntas[key2].posibles_respuestas.respuestas[key3] = vlor2.trim();
                }
                return vlor2.trim();
              });
            } else if(key === 'paired_terms'){
              const vlor = document.getElementById(`enunciadoglobal${key}${key2}`).value;
              if (vlor.trim() !== ''){
                data[key].preguntas[key2].enunciado_individual.enunciado_pregunta = vlor.trim();
              }
              Object.keys(data[key].preguntas[key2].enunciado_individual.terminos.colA).map((key3, index3) => {
                const vlor2 = document.getElementById(`respuestaa${index}${index2}${index3}`).value;
                if (vlor2.trim() !== ''){
                  data[key].preguntas[key2].enunciado_individual.terminos.colA[key3].terms_colA = vlor2.trim();
                }
                return vlor2.trim();
              });
              Object.keys(data[key].preguntas[key2].enunciado_individual.terminos.colB).map((key3, index3) => {
                const vlor3 = document.getElementById(`respuestab${index}${index2}${index3}`).value;
                if (vlor3.trim() !== ''){
                  data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB = vlor3.trim();
                }
                return vlor3.trim();
              });
            } else if(key === 'reading_comprehension' || key === 'development_questions'){
              const vlor = document.getElementById(`enunciadopregunta${key}${key2}`).value;
              if (vlor.trim() !== ''){
                data[key].preguntas[key2].enunciado_individual.enunciado_pregunta = vlor.trim();
                const bodySlides = { "type": key, "subject": subject, "enunciadoglobal": data[key].enunciado_global, "enunciado": vlor.trim(), "yearsold": yearsOld, "rubricaold":  data[key].preguntas[key2].respuesta_correcta};
                const dataComprime = comprimeBody(bodySlides);
                await fetchData(`portal/news/guides/setrubrica`, 'post', { data: dataComprime })
                  .then(response => {
                    if (response.status === false) {
                      console.log(response.error.message || 'Error al obtener rúbrica');
                    }
                    data[key].preguntas[key2].respuesta_correcta = response.data.rubrica;
                  })
                  .catch(error => {
                    console.error('Error al obtener rúbrica', error);
                  });
              }
            } else {
              const vlor = document.getElementById(`enunciadopregunta${key}${key2}`).value;
              if (vlor.trim() !== ''){
                data[key].preguntas[key2].enunciado_individual.enunciado_pregunta = vlor.trim();
              }
            }
            setIsClicked(null);
            setData(prevData => ({ ...prevData, [key]: data[key] }));
            setIsLoading(false);
          }} >
          {isLoading ? CustomSkeleton.iconLoading() : <IoCheckmarkCircleOutline className={`text-white-A700 text-xs`} size="20" title="Guardar" />}
        </Button>
      )}
      <Button 
        key={`add${index}${index2}`}
        className={`edit p-1 hidden ${isClicked === `${key}${key2}` ? 'fade-in' : 'fade-out'}`}
        shape="round"
        color="indigo_A200"
        variant="fill"
        onClick={() => openModalAdd(key, types[key].id_guide_type)}>
        <IoAddCircleOutline className={`text-white-A700 text-xs`} size="22" title="Agregar" />
      </Button>
      <Button 
        key={`delete${index}${index2}`}
        className={`edit p-1 ${isClicked === `${key}${key2}` ? 'fade-in' : 'fade-out'}`}
        shape="round"
        color="indigo_A200"
        variant="fill"
        onClick={() => {
          setIsClicked(null);
          openModalDelete(key, types[key].type, data[key].preguntas[key2].id);
        }}>
        <IoTrashOutline className={`text-white-A700 text-xs`} size="22" title="Eliminar" />
      </Button>
    </div>
  );

  const buttonsViewGlobal = (key, index) => (
    <div className={`edit flex flex-col gap-5 items-center justify-start px-1 py-3 rounded-lg w-auto border-transition bg-transition ${isClicked === `${key}global` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'}`}>
      {(isClicked !== `${key}global`) ? (
        <Button 
          className={`p-1 rounded-lg border border-solid`} 
          shape="round"
          color="white_A700"
          variant="fill"
          disabled={ (loadingProgress === 100) ? false : true }
          onClick={(e) => {
            e.stopPropagation();
            setIsClicked(`${key}global`);
            setSelectedType(`${key}`);
          }} >
          <IoPencil className={`text-gray-600 ${(loadingProgress === 100) ? 'hover:text-gray-900' : ''} text-xs`} size="20" title="Editar" />
        </Button>
      ) : (
        <Button 
          className={`p-1`} 
          shape="round"
          color="indigo_A200"
          variant="fill"
          disabled={isLoading}
          onClick={async (e) => {
            e.stopPropagation();
            setIsLoading(true);
            if(key === 'reading_comprehension'){
              const vlorT = document.getElementById(`enunciadoglobaltitulo${key}`).value;
              if (vlorT.trim() !== ''){
                data[key].enunciado_global.titulo = vlorT.trim();
              }
              const vlorTxt = document.getElementById(`enunciadoglobaltexto${key}`).value;
              if (vlorTxt.trim() !== ''){
                data[key].enunciado_global.texto = vlorTxt.trim();
              }
              const vlorF = document.getElementById(`enunciadoglobalfuente${key}`).value;
              if (vlorF.trim() !== ''){
                data[key].enunciado_global.fuente = vlorF.trim();
              }
            }
            setIsClicked(null);
            setData(prevData => ({ ...prevData, [key]: data[key] }));
            setIsLoading(false);
          }} >
          {isLoading ? CustomSkeleton.iconLoading() : <IoCheckmarkCircleOutline className={`text-white-A700 text-xs`} size="20" title="Guardar" />}
        </Button>
      )}
    </div>
  );

  const contentQuestions = Object.keys(data).map((key, index) => (
    (types && types[key] !== undefined) && (
      (key === 'multiple_choice') ? (
        <div className="flex flex-col gap-4 items-start justify-start w-full" key={index}>
          <Text className="font-semibold leading-[normal] p-0 placeholder:text-gray-900 text-left text-lg border-b-0 border-indigo-A200 border-solid w-full pb-2 max-w-[824px]">
            {types[key].type}
          </Text>
          <Text className="w-full py-2 text-justify max-w-[824px]">
            {types[key].instruction}
          </Text>
          {(data[key] && data[key].enunciado_global !== "") && (
            <Text className="w-full py-2 text-justify max-w-[824px]">
              {data[key].enunciado_global}
            </Text>
          )}
          {((data[key] && data[key].preguntas && Object.keys(data[key].preguntas).length > 0) ? (Object.keys(data[key].preguntas).map((key2, index2) => (
            <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
              key={`${key}${key2 + 1}`}
            >
              <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
                <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                  {habilidadView(data[key].preguntas[key2].habilidad, `${key}${key2}`)}
                  <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${key}${key2}` ? 'overlay-active' : ''}`}>
                    <Text
                      className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                      size="txtInterBold12"
                    >
                      {data[key].preguntas[key2].id}
                    </Text>
                    <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                      <div className={`flex flex-row gap-2 ${isClicked === `${key}${key2}` ? 'items-center' : 'items-start'} justify-start w-full px-2`}>
                        <div className={`edit-border ${isClicked === `${key}${key2}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                          {(isClicked === `${key}${key2}`) ? (
                            <div className="max-w-[678px] text-gray-900 w-full">
                              <TextArea
                                name={`enunciadopregunta${key}${key2}`}
                                id={`enunciadopregunta${key}${key2}`}
                                defaultValue={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[17px]`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `${key}${key2}`) ? false : true }
                              />
                            </div>
                          ) : (
                            <>
                              <div
                                className="max-w-[678px] text-[17px] font-bold text-gray-900 w-full flex flex-row"
                                size="txtNunitoBold17Gray900"
                              >
                                <Latex enunciado={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="flex flex-row h-5 items-center justify-center py-5">
                          <Button 
                            className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                            shape="round"
                            color="white_A700"
                            variant="fill"
                            onClick={() => {
                              setIsClicked(null);
                              openModalImage(key, types[key].type, data[key].preguntas[key2].id, (data[key].preguntas[key2].enunciado_individual.hasOwnProperty('imagen_url')) ? data[key].preguntas[key2].enunciado_individual.imagen_url : '');
                            }}>
                              <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                          </Button>
                        </div>
                      </div>
                      <div id={`container-imagen${index}${index2}`}>
                        {((typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen !== '') ? (data[key].preguntas[key2].enunciado_individual.imagen_url && typeof data[key].preguntas[key2].enunciado_individual.imagen_url === 'string' && data[key].preguntas[key2].enunciado_individual.imagen_url !== '') ? (
                          <>  
                            {(isClicked === `${key}${key2}`) && (
                              <div className='w-full text-right'>
                                <Button
                                  shape="round"
                                  size="xs"
                                  className="px-0"
                                  onClick={() => {
                                    const img = slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url);
                                    if (img) {
                                      const index = slideImage.indexOf(img);
                                      slideImage.splice(index, 1);
                                      data[key].preguntas[key2].enunciado_individual.imagen_url = undefined;
                                      data[key].preguntas[key2].enunciado_individual.imagen = '';
                                      setData(prevData => ({ ...prevData,
                                        [key]: data[key]
                                      }));
                                    }
                                  }}
                                >
                                  <IoCloseCircle />
                                </Button>
                              </div>
                            )}
                            <Img
                              id={`imagen${index}${index2}`}
                              className="object-cover object-center rounded-lg"
                              src={`${slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url)?.img || ""}`}
                            />
                          </>
                        ) : CustomSkeleton.loadingImage(`${index}${index2}`) : null)}
                      </div>
                      <div className="flex flex-col gap-1 h-auto items-start justify-start w-full">
                        <List
                          className="flex flex-col gap-1 items-center w-full"
                          orientation="vertical"
                        >
                          {Object.keys(data[key].preguntas[key2].posibles_respuestas.respuestas).map((key3, index3) => (
                            <div className="flex flex-1 flex-row gap-2.5 items-end justify-start w-full" key={`${index}${index2}${index3}`}>
                              {(isClicked === `${key}${key2}`) ? (
                                <div className={`edit-border flex flex-row gap-2 flex-1 normal-case pl-1 sm:pr-5 pr-[35px] py-[3px] text-base text-gray-900 w-full`}>
                                  <div className='flex flex-row gap-2'>
                                    {<>
                                      <CheckBox 
                                        key={`check${key}${key2 + 1}`}
                                        checked={(data[key].preguntas[key2].respuesta_correcta === key3) ? true : false }
                                        className="text-[10px] justify-start text-left pl-3 z-10" 
                                        label={""}
                                        name={ `check${key}${key2 + 1}` }
                                        id={ `check${key}${key2 + 1}` }
                                        color="teal_A700"
                                        onChange={(value) => {
                                          if (value) {
                                            data[key].preguntas[key2].respuesta_correcta = key3;
                                            setData(prevData => ({ ...prevData, 
                                              [key]: data[key] 
                                            }));
                                          }
                                        }}
                                      />{key3}
                                    </>}
                                  </div>
                                  <TextArea
                                    name={`respuesta${index}${index2}${index3}`}
                                    id={`respuesta${index}${index2}${index3}`}
                                    defaultValue={data[key].preguntas[key2].posibles_respuestas.respuestas[key3]}
                                    placeholder=""
                                    className={`text-base text-left text-gray-900 p-0 w-full`}
                                    size="xxs"
                                    wrapClassName={`w-full hover:border-b-2 hover:border-indigo-A200 hover:border-solid`}
                                  />
                                </div>
                              ) : (
                                <div
                                  className={`edit-border ${isClicked === `${key}${key2}` ? 'hover:border-b-2 hover:border-indigo-A200 hover:border-solid' : ''} flex flex-row gap-2 normal-case pl-1 sm:pr-5 pr-[35px] py-[3px] text-base text-gray-900 w-full`}
                                  size="txtNunitoRegular16Gray900"
                                >
                                  <div>{`${key3}) `}</div><Latex enunciado={data[key].preguntas[key2].posibles_respuestas.respuestas[key3]} />
                                </div>
                              )}
                              {/* <div className='flex flex-row h-5 items-center justify-center py-5'>
                                <Button 
                                  className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                                  shape="round"
                                  color="white_A700"
                                  variant="fill"
                                  onClick={() => {
                                    setIsClicked(null);
                                    //openModalImage(key, types[key].type, data[key].preguntas[key2].id);
                                  }}>
                                  <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                                </Button>
                              </div> */}
                              <div className="flex flex-col items-end justify-start h-5 w-5">
                              </div>
                            </div>
                          ))}
                        </List>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {buttonsView(key, key2, index, index2)}
            </div>
          ))) : CustomSkeleton.loadingMultipleChoiceCount(types[key].n, index))}
        </div>
      ) :
      (key === 'true_false') ? (
        <div className="flex flex-col gap-4 items-start justify-start w-full" key={index}>
          <Text className="font-semibold leading-[normal] p-0 placeholder:text-gray-900 text-left text-lg border-b-0 border-indigo-A200 border-solid w-full pb-2 max-w-[824px]">
            {types[key].type}
          </Text>
          <Text className="w-full py-2 text-justify max-w-[824px]">
            {types[key].instruction}
          </Text>
          {(data[key] && data[key].enunciado_global !== "") && (
            <Text className="w-full py-2 text-justify max-w-[824px]">
              {data[key].enunciado_global}
            </Text>
          )}
          {((data[key] && data[key].preguntas && Object.keys(data[key].preguntas).length > 0) ? Object.keys(data[key].preguntas).map((key2, index2) => (
            <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
              key={`${key}${key2 + 1}`}
            >
              <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
                <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                  {habilidadView(data[key].preguntas[key2].habilidad, `${key}${key2}`)}
                  <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${key}${key2}` ? 'overlay-active' : ''}`}>
                    <Text
                      className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                      size="txtInterBold12"
                    > 
                      {data[key].preguntas[key2].id}
                    </Text>
                    <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                      <div className={`flex flex-row gap-2 ${isClicked === `${key}${key2}` ? 'items-center' : 'items-start'} justify-start max-w-[719px] w-full px-2`}>
                          {(isClicked === `${key}${key2}`) ? (
                            <div className={`font-bold text-gray-900 leading-[normal] p-0 text-left w-full edit-border ${isClicked === `${key}${key2}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col`}>
                              <TextArea
                                name={`enunciadopregunta${key}${key2}`}
                                id={`enunciadopregunta${key}${key2}`}
                                defaultValue={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                                placeholder=""
                                className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[17px]`}
                                wrapClassName={`w-full bg-gray-50`}
                                size="xxs"
                                disabled={ (isClicked === `${key}${key2}`) ? false : true }
                              />
                            </div>
                          ) : (
                            <div
                              className={`font-bold text-gray-900 leading-[normal] p-0 text-[17px] text-left w-full edit-border flex flex-1 flex-col`}
                            >
                              <Latex enunciado={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} />
                            </div>
                          )}
                        <div className="flex flex-row gap-2 items-center justify-start w-auto">
                          {(isClicked === `${key}${key2}`) ? (
                              <>
                                <Button 
                                  key={`buttont${key}${key2 + 1}`}
                                  className={`border border-blue_gray-200 border-solid h-[30px] justify-center py-1 rounded-lg text-base text-center w-10 ${(isClicked === `${key}${key2}`) && (data[key].preguntas[key2].respuesta_correcta === "V" || data[key].preguntas[key2].respuesta_correcta.toUpperCase() === "VERDADERO") ? 'bg-teal-A700 text-white-A700' : 'bg-gray-50 text-gray-700'}`}
                                  name={ `buttont${key}${key2 + 1}` }
                                  id={ `buttont${key}${key2 + 1}` }
                                  color={(isClicked === `${key}${key2}`) && (data[key].preguntas[key2].respuesta_correcta === "V" || data[key].preguntas[key2].respuesta_correcta.toUpperCase() === "VERDADERO") ? "teal_A700" : "gray_100"}
                                  onClick={() => {
                                    data[key].preguntas[key2].respuesta_correcta = (idSubject === 4) ? "True" : "Verdadero";
                                    setData(prevData => ({ ...prevData, 
                                      [key]: data[key] 
                                    }));
                                  }}
                                >{(idSubject === 4) ? ("T") : ("V")}</Button>
                                <Button 
                                  key={`buttonf${key}${key2 + 1}`}
                                  className={`border border-blue_gray-200 border-solid h-[30px] justify-center py-1 rounded-lg text-base text-center w-10 ${(isClicked === `${key}${key2}`) && (data[key].preguntas[key2].respuesta_correcta === "F" || data[key].preguntas[key2].respuesta_correcta.toUpperCase() === "FALSO") ? 'bg-teal-A700 text-white-A700' : 'bg-gray-50 text-gray-700'}`}
                                  name={ `buttonf${key}${key2 + 1}` }
                                  id={ `buttonf${key}${key2 + 1}` }
                                  color={(isClicked === `${key}${key2}`) && (data[key].preguntas[key2].respuesta_correcta === "F" || data[key].preguntas[key2].respuesta_correcta.toUpperCase() === "FALSO") ? "teal_A700" : "gray_100"}
                                  onClick={() => {
                                    data[key].preguntas[key2].respuesta_correcta = (idSubject === 4) ? "False" : "Falso";;
                                    setData(prevData => ({ ...prevData, 
                                      [key]: data[key] 
                                    }));
                                  }}
                                >F</Button>
                              </>) : (
                              <>
                                <Text
                                  className={`border border-blue_gray-200 border-solid h-[30px] justify-center py-1 rounded-lg text-base text-center w-10 bg-gray-50 text-gray-700`}
                                  size="txtNunitoRegular16Gray700"
                                >
                                  {(idSubject === 4) ? ("T") : ("V")}
                                </Text>
                                <Text
                                  className={`border border-blue_gray-200 border-solid h-[30px] justify-center py-1 rounded-lg text-base text-center w-10 bg-gray-50 text-gray-700`}
                                  size="txtNunitoRegular16Gray700"
                                >
                                  F
                                </Text>
                              </>
                            )
                          }
                        </div>
                        <div className="flex flex-row h-5 items-center justify-center">
                          <Button 
                            className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                            shape="round"
                            color="white_A700"
                            variant="fill"
                            onClick={() => {
                              setIsClicked(null);
                              openModalImage(key, types[key].type, data[key].preguntas[key2].id);
                            }}>
                            <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                          </Button>
                        </div>
                      </div>
                      {(typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen !== '') ? ((typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen_url) ? (
                        <div id={`container-imagen${index}${index2}`}>
                          {(isClicked === `${key}${key2}`) ? (
                            <div className='w-full text-right'>
                              <Button
                                shape="round"
                                size="xs"
                                className="px-0"
                                onClick={() => {
                                  const img = slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url);
                                  if (img) {
                                    const index = slideImage.indexOf(img);
                                    slideImage.splice(index, 1);
                                    data[key].preguntas[key2].enunciado_individual.imagen_url = undefined;
                                    data[key].preguntas[key2].enunciado_individual.imagen = '';
                                    setData(prevData => ({ ...prevData,
                                      [key]: data[key]
                                    }));
                                  }
                                }}
                              >
                                <IoCloseCircle />
                              </Button>
                            </div>
                          ) : null}
                          <Img
                            id={`imagen${index}${index2}`}
                            className="w-[300px] h-auto max-h-[300px] object-cover object-center rounded-lg"
                            src={`${slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url)?.img || ""}`}
                          />
                        </div>
                      ) : CustomSkeleton.loadingImage(`${index}${index2}`)) : null}
                    </div>
                  </div>
                </div>
              </div>
              {buttonsView(key, key2, index, index2)}
            </div>
          )) : CustomSkeleton.loadingTrueFalseCount(types[key].n, index))}
        </div>
      ) :
      (key === 'paired_terms') ? (
        <div className="flex flex-col gap-4 items-start justify-start w-full" key={index}>
          <Text className="font-semibold leading-[normal] p-0 placeholder:text-gray-900 text-left text-lg border-b-0 border-indigo-A200 border-solid w-full pb-2 max-w-[824px]">
            {types[key].type}
          </Text>
          <Text className="w-full py-2 text-justify max-w-[824px]">
            {types[key].instruction}
          </Text>
          {((data[key] && data[key].preguntas && Object.keys(data[key].preguntas).length > 0) ? Object.keys(data[key].preguntas).map((key2, index2) => (
            <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
              key={`${key}${key2 + 1}`}
            >
              <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
                <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                  {habilidadView(data[key].preguntas[key2].habilidad, `${key}${key2}`)}
                  <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${key}${key2}` ? 'overlay-active' : ''}`}>
                    <Text
                      className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                      size="txtInterBold12"
                    >
                      {data[key].preguntas[key2].id}
                    </Text>
                    <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                      <div className={`flex flex-row gap-2.5 ${isClicked === `${key}${key2}` ? 'items-center' : 'items-start'} justify-start w-full px-2`}>
                        <div className={`edit-border ${isClicked === `${key}${key2}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                            {(isClicked === `${key}${key2}`) ? (
                              <div className="max-w-[678px] font-bold text-gray-900 w-full">
                                <TextArea
                                  name={`enunciadoglobal${key}${key2}`}
                                  id={`enunciadoglobal${key}${key2}`}
                                  defaultValue={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[17px]`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `${key}${key2}`) ? false : true }
                                />
                              </div>
                            ) : (
                              <div
                                className="max-w-[678px] text-[17px] font-bold text-gray-900 w-full"
                                size="txtNunitoBold17Gray900"
                              >
                                <Latex enunciado={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} />
                              </div>
                            )}
                        </div>
                        {/* <div className="flex flex-row h-5 items-center justify-center">
                          <Button 
                            className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                            shape="round"
                            color="white_A700"
                            variant="fill"
                            onClick={() => {
                              setIsClicked(null);
                              openModalImage(key, types[key].type, data[key].preguntas[key2].id);
                            }}>
                            <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                          </Button>
                        </div> */}
                      </div>
                      <div className="flex flex-col h-auto items-start justify-start w-full mt-4">
                        <div className="flex md:flex-col flex-row gap-4 items-start justify-start py-2 px-2 w-full">
                          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                            <Text
                              className="bg-indigo-A200 border border-indigo-A200 border-solid h-[30px] justify-center py-1 rounded-lg text-base text-center text-white-A700 w-full"
                              size="txtNunitoRegular16WhiteA700"
                            >
                              Columna A
                            </Text>
                            <List
                              className="bg-white-A700 border border-indigo-A200 border-solid flex flex-col gap-4 items-start px-4 py-5 rounded-lg w-full"
                              orientation="vertical"
                            >
                              {(data[key].preguntas[key2].enunciado_individual && data[key].preguntas[key2].enunciado_individual.terminos && data[key].preguntas[key2].enunciado_individual.terminos.colA) ? Object.keys(data[key].preguntas[key2].enunciado_individual.terminos.colA).map((key3, index3) => (
                                <div className="flex flex-1 flex-row gap-2 items-center justify-start my-0 w-full" key={`A${index}${index2}${index3}`}>
                                  <div className="bg-indigo-A200 w-auto border border-gray-500 rounded text-white-A700 px-1 align-middle">
                                    { data[key].preguntas[key2].enunciado_individual.terminos.colA[key3].id_colA}
                                  </div>
                                    {(isClicked === `${key}${key2}`) ? (
                                      <div className={`edit-border flex-1 py-1 text-base text-gray-900 w-full`}>
                                        <TextArea
                                          name={`respuestaa${index}${index2}${index3}`}
                                          id={`respuestaa${index}${index2}${index3}`}
                                          defaultValue={data[key].preguntas[key2].enunciado_individual.terminos.colA[key3].terms_colA}
                                          placeholder=""
                                          className={`text-base text-left text-gray-900 p-0 w-full`}
                                          size="xxs"
                                          wrapClassName={`w-full hover:border-b-2 hover:border-indigo-A200 hover:border-solid`}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className={`edit-border ${isClicked === `${key}${key2}` ? 'hover:border-b-2 hover:border-indigo-A200 hover:border-solid' : ''} flex-1 py-1 text-base text-gray-900 w-full`}
                                        size="txtNunitoRegular16Gray900"
                                      >
                                        <Latex enunciado={data[key].preguntas[key2].enunciado_individual.terminos.colA[key3].terms_colA} />
                                      </div>
                                    )}
                                </div>  
                              )) : null}
                            </List>
                          </div>
                          <div className="flex flex-1 flex-col gap-2.5 items-center justify-center w-full">
                            <Text
                              className="bg-teal-A700 border border-solid border-teal-A700 h-[30px] justify-center py-1 rounded-lg text-base text-center text-white-A700 w-full"
                              size="txtNunitoRegular16WhiteA700"
                            >
                              Columna B
                            </Text>
                            <List
                              className="bg-white-A700 border border-teal-A700 border-solid flex flex-col gap-4 items-start px-4 py-5 rounded-lg w-full"
                              orientation="vertical"
                            >
                              {(data[key].preguntas[key2].enunciado_individual && data[key].preguntas[key2].enunciado_individual && data[key].preguntas[key2].enunciado_individual.respuestas && data[key].preguntas[key2].enunciado_individual.terminos && data[key].preguntas[key2].enunciado_individual.terminos.colB) ? Object.keys(data[key].preguntas[key2].enunciado_individual.terminos.colB).map((key3, index3) => (
                                <div className="flex flex-1 flex-row gap-2 items-center justify-start my-0 w-full" key={`B${index}${index2}${index3}`}>
                                  <div className={`min-w-[19px] min-h-[23px] w-auto border border-gray-500 rounded align-middle ${(isClicked === `${key}${key2}`) ? 'bg-teal-A700 text-white-A700' : 'bg-gray-50 text-gray-700'}`}>
                                    {(isClicked === `${key}${key2}` && Object.keys(data[key].preguntas[key2].enunciado_individual.respuestas).length > 0) ? (
                                      <Input
                                        name={`option${key}${key2 + 1}`}
                                        id={`option${key}${key2 + 1}`}
                                        defaultValue={Object.keys(data[key].preguntas[key2].enunciado_individual.respuestas).find(k => data[key].preguntas[key2].enunciado_individual.respuestas[k] === data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB)}
                                        className={`bg-transparent text-center font-bold text-sm text-white-A700 px-1 py-0 w-[18px]`}
                                        wrapClassName="bg-transparent w-full m-auto items-center justify-center"
                                        shape="round"
                                        maxLength={1}
                                        size="xxs"
                                        type="number"
                                        onClick={ (e) => { 
                                          e.target.select();
                                        }}
                                        onInput={ (e) => {
                                          if (e.target.value < 1 || e.target.value > 4 || e.target.value.length > 1) {
                                            e.target.value = e.target.value.slice(0, -1);
                                          }
                                        }}
                                        onChange={ async (value) => {
                                          if(value === "" || (data[key].preguntas[key2].enunciado_individual.respuestas[value] !== "" && Object.keys(data[key].preguntas[key2].enunciado_individual.respuestas).find(k => data[key].preguntas[key2].enunciado_individual.respuestas[k] === data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB) !== undefined)) {
                                            data[key].preguntas[key2].enunciado_individual.respuestas[Object.keys(data[key].preguntas[key2].enunciado_individual.respuestas).find(k => data[key].preguntas[key2].enunciado_individual.respuestas[k] === data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB)] = "";
                                            await setData(prevData => ({ ...prevData,
                                              [key]: data[key]
                                            }));
                                          }

                                          if (value >= 1 && value <= Object.keys(data[key].preguntas[key2].enunciado_individual.respuestas).length) {
                                            data[key].preguntas[key2].enunciado_individual.respuestas[value] = data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB;
                                            await setData(prevData => ({ ...prevData,
                                              [key]: data[key]
                                            }));
                                          }
                                        }}
                                      ></Input>
                                    ) : null}
                                  </div>
                                  {(isClicked === `${key}${key2}`) ? (
                                    <div className={`edit-border flex-1 py-1 text-base text-gray-900 w-full`}>
                                      <TextArea
                                        name={`respuestab${index}${index2}${index3}`}
                                        id={`respuestab${index}${index2}${index3}`}
                                        defaultValue={data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB}
                                        placeholder=""
                                        className={`text-base text-left text-gray-900 p-0 w-full`}
                                        size="xxs"
                                        wrapClassName={`w-full hover:border-b-2 hover:border-indigo-A200 hover:border-solid`}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className={`edit-border ${isClicked === `${key}${key2}` ? 'hover:border-b-2 hover:border-indigo-A200 hover:border-solid' : ''} flex-1 py-1 text-base text-gray-900 w-full`}
                                      size="txtNunitoRegular16Gray900"
                                    >
                                      <Latex enunciado={data[key].preguntas[key2].enunciado_individual.terminos.colB[key3].terms_colB} />
                                    </div>
                                  )}
                                </div>  
                              )) : null}
                            </List>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {buttonsView(key, key2, index, index2)}
            </div>
          )) : CustomSkeleton.loadingPairedTermsCount(types[key].n, index))}
        </div>
      ) :
      (key === 'reading_comprehension') ? (
        <div className="flex flex-col gap-4 items-start justify-start w-full" key={index}>
          <Text className="font-semibold leading-[normal] p-0 placeholder:text-gray-900 text-left text-lg border-b-0 border-indigo-A200 border-solid w-full pb-2 max-w-[824px]">
            {types[key].type}
          </Text>
          <Text className="w-full py-2 text-justify max-w-[824px]">
            {types[key].instruction}
          </Text>
          
          {(data[key] && data[key].enunciado_global) ? (
            <div className="w-full py-2 text-justify max-w-[824px]">
              <div className="flex flex-col gap-4 items-center justify-center w-auto">
                <div className='flex flex-row w-full items-center justify-center'>
                  {(isClicked === `${key}global`) ? (
                    <div className="font-bold text-gray-900 w-full">
                      <TextArea
                        name={`enunciadoglobaltitulo${key}`}
                        id={`enunciadoglobaltitulo${key}`}
                        defaultValue={String.raw`${data[key].enunciado_global.titulo.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                        placeholder=""
                        className={`bg-gray-50 text-base text-center text-gray-900 p-0 w-full font-bold text-[17px] ${isClicked === `${key}global` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                        wrapClassName={`w-full bg-gray-50`}
                        size="xxs"
                        disabled={ (isClicked === `${key}global`) ? false : true }
                      />
                    </div>
                  ) : (
                    <div
                      className="w-full border-b-0 border-indigo-A200 bg-gray_50 border-solid cursor-pointer font-bold leading-[normal] text-[17px] text-center py-1"
                    >
                      {data[key].enunciado_global.titulo}
                    </div>
                  )}
                  {buttonsViewGlobal(key, index)}
                </div>
                <div className="flex md:flex-col flex-row gap-2 items-end justify-start w-full">
                  <div className={`bg-white-A700 flex flex-1 flex-col h-full items-start justify-start p-2 w-full`}>
                    {(isClicked === `${key}global`) ? (
                      <div className="text-gray-900 w-full">
                        <TextArea
                          name={`enunciadoglobaltexto${key}`}
                          id={`enunciadoglobaltexto${key}`}
                          defaultValue={String.raw`${data[key].enunciado_global.texto.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                          placeholder=""
                          className={`bg-gray-50 text-base text-justify text-gray-900 p-0 w-full italic ${isClicked === `${key}global` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                          wrapClassName={`w-full bg-gray-50`}
                          size="xxs"
                          disabled={ (isClicked === `${key}global`) ? false : true }
                        />
                      </div>
                    ) : (
                      <Text
                        className="italic leading-[26.00px] w-full text-base text-gray-900"
                        size="txtNunitoItalicRegular16"
                      >
                        {data[key].enunciado_global.texto}
                      </Text>
                    )}
                    {(isClicked === `${key}global`) ? (
                      <div className="text-gray-900 w-full">
                        <TextArea
                          name={`enunciadoglobalfuente${key}`}
                          id={`enunciadoglobalfuente${key}`}
                          defaultValue={String.raw`${data[key].enunciado_global.fuente.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                          placeholder=""
                          className={`bg-gray-50 text-xs text-justify text-gray-900 p-0 w-full ${isClicked === `${key}global` ? 'border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''}`}
                          wrapClassName={`w-full bg-gray-50`}
                          size="xxs"
                          disabled={ (isClicked === `${key}global`) ? false : true }
                        />
                      </div>
                    ) : (
                      <Text
                        className="w-full text-xs text-gray-900 mt-2"
                        size="txtNunitoItalicRegular16"
                      >
                        {data[key].enunciado_global.fuente}
                      </Text>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <Text
                    className="text-base text-gray-900 w-auto"
                    size="txtNunitoRegular16Gray900"
                  >
                    {(idSubject === 4) ? ("Reply:") : ("Ahora responde:")}
                  </Text>
                </div>
              </div>
            </div>
          ) : null}
          {((data[key] && data[key].preguntas && Object.keys(data[key].preguntas).length > 0) ? Object.keys(data[key].preguntas).map((key2, index2) => (
            <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
              key={`${key}${key2 + 1}`}
            >
              <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
                <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                  {habilidadView(data[key].preguntas[key2].habilidad, `${key}${key2}`)}
                  <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${key}${key2}` ? 'overlay-active' : ''}`}>
                    <Text
                      className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                      size="txtInterBold12"
                    >
                      {data[key].preguntas[key2].id}
                    </Text>
                    <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                      <div className={`flex flex-row gap-2.5 ${isClicked === `${key}${key2}` ? 'items-center' : 'items-start'} justify-start w-full px-2`}>
                        <div className={`edit-border ${isClicked === `${key}${key2}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                            {(isClicked === `${key}${key2}`) ? (
                              <div className="max-w-[678px] font-bold text-gray-900 w-full">
                                <TextArea
                                  name={`enunciadopregunta${key}${key2}`}
                                  id={`enunciadopregunta${key}${key2}`}
                                  defaultValue={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[17px]`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `${key}${key2}`) ? false : true }
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="max-w-[678px] text-[17px] font-bold text-gray-900 w-full"
                                  size="txtNunitoBold17Gray900"
                                >
                                  <Latex enunciado={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} />
                                </div>
                              </>
                            )}
                        </div>
                        <div className="flex flex-row h-5 items-center justify-center">
                          <Button 
                            className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                            shape="round"
                            color="white_A700"
                            variant="fill"
                            onClick={() => {
                              setIsClicked(null);
                              openModalImage(key, types[key].type, data[key].preguntas[key2].id);
                            }}>
                            <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                          </Button>
                        </div>
                      </div>
                      {(typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen !== '') && ((typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen_url) ? (
                        <div id={`container-imagen${index}${index2}`}>
                          {(isClicked === `${key}${key2}`) && (
                            <div className='w-full text-right'>
                              <Button
                                shape="round"
                                size="xs"
                                className="px-0"
                                onClick={() => {
                                  const img = slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url);
                                  if (img) {
                                    const index = slideImage.indexOf(img);
                                    slideImage.splice(index, 1);
                                    data[key].preguntas[key2].enunciado_individual.imagen_url = undefined;
                                    data[key].preguntas[key2].enunciado_individual.imagen = '';
                                    setData(prevData => ({ ...prevData,
                                      [key]: data[key]
                                    }));
                                  }
                                }}
                              >
                                <IoCloseCircle />
                              </Button>
                            </div>
                          )}
                          <Img
                            id={`imagen${index}${index2}`}
                            className="w-[300px] h-auto max-h-[300px] object-cover object-center rounded-lg"
                            src={`${slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url)?.img || ""}`}
                          />
                        </div>
                      ) : (CustomSkeleton.loadingImage(`${index}${index2}`)))}
                      <div className="flex flex-col items-start justify-start w-full pr-[20px]">
                        <WritingLines editing={isClicked === `${key}${key2}` ? true : false} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {buttonsView(key, key2, index, index2)}
            </div>
          )) : ((CustomSkeleton.loadingOtherCount(types[key].n, index))))}
        </div>
      ) :
      ((key === 'development_questions') || (key === 'exercises') || (key === 'metacognition')) ? (
        <div className="flex flex-col gap-4 items-start justify-start w-full" key={index}>
          <Text className="font-semibold leading-[normal] p-0 placeholder:text-gray-900 text-left text-lg border-b-0 border-indigo-A200 border-solid w-full pb-2 max-w-[824px]">
            {types[key].type}
          </Text>
          <Text className="w-full py-2 text-justify max-w-[824px]">
            {types[key].instruction}
          </Text>
          {(data[key] && data[key].enunciado_global !== "") && (
            <div className="w-full py-2 text-justify max-w-[824px]">
              <Latex enunciado={data[key].enunciado_global} />
            </div>
          )}
          {((data[key] && data[key].preguntas && Object.keys(data[key].preguntas).length > 0) ? Object.keys(data[key].preguntas).map((key2, index2) => (
            <div className="flex flex-row gap-2 items-start justify-start max-w-[868px] w-full"
              key={`${key}${key2 + 1}`}
            >
              <div className="flex flex-1 flex-row items-start justify-start max-w-[824px] w-full">
                <div className={`flex flex-row gap-0 items-start justify-start border-transition bg-transition ${isClicked === `${key}${key2}` ? 'border-visible animation-paint ' : 'border-hidden animation-unpaint'} max-w-[800px] rounded-lg w-full`}>
                  {habilidadView(data[key].preguntas[key2].habilidad, `${key}${key2}`)}
                  <div className={`flex flex-row gap-1 bg-white-A700 w-full pl-2 rounded-tr-lg rounded-br-lg overlay ${isClicked === `${key}${key2}` ? 'overlay-active' : ''}`}>
                    <Text
                      className="border border-gray-600 border-solid flex h-5 items-center justify-center rounded-[50%] text-center text-gray-600 text-xs w-5 mt-6"
                      size="txtInterBold12"
                    >
                      {data[key].preguntas[key2].id}
                    </Text>
                    <div className="flex flex-1 flex-col font-nunito gap-2 items-start justify-start max-w-[724px] w-full my-6">
                      <div className={`flex flex-row gap-2.5 ${isClicked === `${key}${key2}` ? 'items-center' : 'items-start'} justify-start w-full px-2`}>
                        <div className={`edit-border ${isClicked === `${key}${key2}` ? 'bg-gray-50 border-b-2 border-indigo-A200 border-solid hover:border-indigo-A900' : ''} flex flex-1 flex-col items-start justify-start px-2 w-full`}>
                            {(isClicked === `${key}${key2}`) ? (
                              <div className="max-w-[678px] font-bold text-gray-900 w-full">
                                <TextArea
                                  name={`enunciadopregunta${key}${key2}`}
                                  id={`enunciadopregunta${key}${key2}`}
                                  defaultValue={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`}
                                  placeholder=""
                                  className={`bg-gray-50 text-base text-left text-gray-900 p-0 w-full font-bold text-[17px]`}
                                  wrapClassName={`w-full bg-gray-50`}
                                  size="xxs"
                                  disabled={ (isClicked === `${key}${key2}`) ? false : true }
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="max-w-[678px] text-[17px] font-bold text-gray-900 w-full"
                                  size="txtNunitoBold17Gray900"
                                >
                                  <Latex enunciado={String.raw`${data[key].preguntas[key2].enunciado_individual.enunciado_pregunta.replace(/\t/g, '\\t').replace(/\n/g, '\\n')}`} />
                                </div>
                              </>
                            )}
                        </div>
                        <div className="flex flex-row h-5 items-center justify-center">
                          <Button 
                            className={`edit ${isClicked === `${key}${key2}` ? '' : 'hidden'}`} 
                            shape="round"
                            color="white_A700"
                            variant="fill"
                            onClick={() => {
                              setIsClicked(null);
                              openModalImage(key, types[key].type, data[key].preguntas[key2].id);
                            }}>
                            <IoImageOutline size={20} className={`edit text-gray-600 hover:text-gray-900 ${isClicked === `${key}${key2}` ? '' : 'hidden'}`}/>
                          </Button>
                        </div>
                      </div>
                      {(typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen !== '') && ((typeof data[key].preguntas[key2].enunciado_individual.imagen === 'string' && data[key].preguntas[key2].enunciado_individual.imagen_url) ? (
                        <div id={`container-imagen${index}${index2}`}>
                          {(isClicked === `${key}${key2}`) && (
                            <div className='w-full text-right'>
                              <Button
                                shape="round"
                                size="xs"
                                className="px-0"
                                onClick={() => {
                                  const img = slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url);
                                  if (img) {
                                    const index = slideImage.indexOf(img);
                                    slideImage.splice(index, 1);
                                    data[key].preguntas[key2].enunciado_individual.imagen_url = undefined;
                                    data[key].preguntas[key2].enunciado_individual.imagen = '';
                                    setData(prevData => ({ ...prevData,
                                      [key]: data[key]
                                    }));
                                  }
                                }}
                              >
                                <IoCloseCircle />
                              </Button>
                            </div>
                          )}
                          <Img
                            id={`imagen${index}${index2}`}
                            className="w-[300px] h-auto max-h-[300px] object-cover object-center rounded-lg"
                            src={`${slideImage.find(imgr => imgr.id === data[key].preguntas[key2].enunciado_individual.imagen_url)?.img || ""}`}
                          />
                        </div>
                      ) : (CustomSkeleton.loadingImage(`${index}${index2}`)))}
                      {(key === 'exercises') ? (
                        <>
                          <div className="flex flex-col items-start justify-start w-full pr-[20px]">
                            <WritingLines defaultLines={1} color='bg-white-A700' editing={isClicked === `${key}${key2}` ? true : false} />
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-col items-start justify-start w-full pr-[20px]">
                          <WritingLines editing={isClicked === `${key}${key2}` ? true : false} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {buttonsView(key, key2, index, index2)}
            </div>
          )) : (CustomSkeleton.loadingOtherCount(types[key].n, index)))}
        </div>
      ) : null
    ))
  )

  const output = (
    <>
      {contentQuestions}
    </>
  );

  return output;
}

export { Questions };
import React from "react";
import { FaSpinner, FaUser } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";
import { TbLetterX } from "react-icons/tb";
import { Skeleton } from '@mui/material';
import {Img} from "../../components";
const widthLoadingIcon = 20;

const iconLoading = (index = "load", color = "") => (<FaSpinner className={`loading-icon ${color} text-sm`} title="Cargando" key={index} />);

const loadingTypesGuidesSets = (n = 1, index = "tyas") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(<Img
      key={`img${i}`}
      className="w-full h-[50px] mt-1"
      src="/images/img_vector.svg"
    />);
  }
  return <>{elements}</>;
};

const loadingTypesActivitiesSets = (n = 1, index = "tyas") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(<div className='flex flex-col gap-2' key={`${index}${i}`}>
        <div className='flex flex-row gap-4' key={`${index}${i}1`}>
          <Skeleton className="w-[100px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[200px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[150px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
        </div>
        <div className='flex flex-row gap-4' key={`${index}${i}2`}>
          <Skeleton className="w-[250px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[150px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[200px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
        </div>
        <div className='flex flex-row gap-4' key={`${index}${i}3`}>
          <Skeleton className="w-[200px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[250px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
          <Skeleton className="w-[100px]" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
        </div>
      </div>);
  }
  return <>{elements}</>;
};

const loadingTypesSets = (n = 1, index = "tys") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(<div className='flex flex-row gap-4 w-full' key={`${index}${i}`}>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 10} animation="wave" />
      <div className="flex flex-row gap-1 w-full">
        <Skeleton variant="circular" width={widthLoadingIcon + 8} height={widthLoadingIcon + 8} />
        <Skeleton className="w-[40px]" variant="rounded" height={widthLoadingIcon + 8} />
        <Skeleton variant="circular" width={widthLoadingIcon + 8} height={widthLoadingIcon + 8} />
      </div>
    </div>);
  }
  return <>{elements}</>;
};

const loadingTypes = (index = "ty") => (
  <div className='flex flex-col gap-4 mb-4 w-[80%]' key={index} >
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
  </div>
);

const loadingLibraryList = (index = "ll") => (
  <div className='flex flex-col gap-4 mb-4 w-full' key={index} >
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon + 20} animation="wave" />
    </div>
  </div>
);

const loadingLibraryGroup = (index = "lg") => (
  <div className='grid gap-4 grid-cols-5 md:grid-cols-3 sm:grid-cols-2 mb-4 w-full col-span-5' key={index} >
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
    <div className='min-w-[150px] max-w-[249px] w-full'>
      <Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 140} animation="wave" />
    </div>
  </div>
);

const loadingOasSmall = (index = "oas") => (
  <div className='flex flex-col gap-1 w-full' key={index} >
    <div className='flex flex-row gap-1 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon - 15} height={widthLoadingIcon - 15} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} />
    </div>
    <div className='flex flex-row gap-1 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon - 15} height={widthLoadingIcon - 15} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} />
    </div>
  </div>
);

const loadingTextSmall = (index = "oas") => (
  <div className='flex flex-col gap-1 w-full' key={index} >
    <div className='flex flex-row gap-2 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} />
    </div>
    <div className='flex flex-row gap-2 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} />
    </div>
  </div>
);

const loadingOasSets = (n = 1, index = "oass") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(<div className='flex flex-row gap-4 w-full' key={`${index}${i}`}>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} />
    </div>);
  }
  return <>{elements}</>;
}

const loadingImage = (index = "img") => (
  <div className='flex flex-col gap-1 mb-4 w-full' key={index} >
    <div className='flex flex-row gap-1 w-full'>
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} animation="wave" />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-row gap-1 w-full'>
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} animation="wave" />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} />
    </div>
    <div className='flex flex-row gap-1 w-full'>
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} animation="wave" />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton variant="rounded" width={widthLoadingIcon} height={widthLoadingIcon} animation="wave" />
    </div>
  </div>
);

const loadingHeader = (index = "lh") => (
  <div className='flex flex-col gap-2 w-full' key={index}>
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-col gap-2 w-full'>
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon} />
    </div>
  </div>
);

const loadingPage = (index = "p") => (
  <div className='flex flex-col gap-10 mb-4 mt-[60px] w-full max-w-[900px] mx-auto justify-start' key={index} >
    <div className='flex flex-col gap-4 w-full'>
      { loadingHeader(`lh${index}0`) }
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 4} />
    </div>
    <div className='flex flex-col gap-4 mb-4 w-full'>
      { loadingHeader(`lh${index}1`) }
      <div className='flex flex-row gap-4 mb-4 w-full'>
        <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 6} />
        <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 6} />
        <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 6} />
      </div>
    </div>
  </div>
);

const loadingMultipleChoice = (index = "mc") => (
  <div className='flex flex-col gap-4 w-full mb-10 ml-5' key={index} >
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-[80%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className='flex flex-col gap-2 ml-9'>
      <div className='flex flex-row gap-2 w-full'>
        <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
        <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} />
      </div>
      <div className='flex flex-row gap-2 w-full'>
        <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
        <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} />
      </div>
      <div className='flex flex-row gap-2 w-full'>
        <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
        <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} />
      </div>
      <div className='flex flex-row gap-2 w-full'>
        <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
        <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} />
      </div>
    </div>
  </div>
);

const loadingTrueFalse = (index = "tf") => (
  <div className='flex flex-row gap-4 w-full mb-10 ml-5' key={index} >
    <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
    <Skeleton className="w-[80%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
    <Skeleton className="w-[25px]" variant="rounded" height={widthLoadingIcon} />
    <Skeleton className="w-[25px]" variant="rounded" height={widthLoadingIcon} />
  </div>
);

const loadingPairedTerms = (index = "pt") => (
  <div className='flex flex-col gap-4 w-full mt-7 mb-10 ml-5' key={index} >
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-[80%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className="flex flex-row gap-10 ml-9 w-[80%]">
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 8} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 8} />
    </div>
  </div>
);

const loadingOther = (index = "oth") => (
  <div className='flex flex-col gap-4 w-full mb-10 ml-5' key={index} >
    <div className='flex flex-row gap-4 w-full'>
      <Skeleton variant="circular" width={widthLoadingIcon} height={widthLoadingIcon} />
      <Skeleton className="w-[80%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
    </div>
    <div className="flex flex-col gap-4 pl-9 pr-1 w-[85%] mt-4">
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} key={`${index}1`} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} key={`${index}2`} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} key={`${index}3`} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} key={`${index}4`} />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon - 15} key={`${index}5`} />
    </div>
  </div>
);

const loadingGuide = (index = "g") => (
  <div className='flex flex-col gap-10 mb-4 mt-[60px] w-full' key={index} >
    <div className='flex flex-col gap-4 w-full'>
      <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 3} />
    </div>
    <div className='flex flex-col gap-10 mb-4 w-full'>
      { loadingHeader(`lh${index}1`) }
      <div className='flex flex-col gap-4 mb-4 w-full'>
        { loadingMultipleChoice(`mc${index}`) }
      </div>
    </div>
    <div className='flex flex-col gap-10 mb-4 w-full'>
      { loadingHeader(`lh${index}2`) }
      <div className='flex flex-col gap-4 mb-4 w-full'>
        { loadingTrueFalse(`tf${index}1`) }
        { loadingTrueFalse(`tf${index}2`) }
        { loadingTrueFalse(`tf${index}3`) }
      </div>
    </div>
    <div className='flex flex-col gap-10 mb-4 w-full'>
      { loadingHeader(`lh${index}4`) }
      <div className='flex flex-col gap-4 mb-4 w-full'>
        { loadingOther(`pt${index}`) }
      </div>
    </div>
  </div>
);

const loadingActivity = (index = "a") => (
  <div className='flex flex-col gap-10 mb-4 mt-[60px] w-full' key={index} >
    <div className='flex flex-col gap-4 w-full'>
      <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
      <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 3} />
    </div>
    <div className='flex flex-col gap-10 mb-4 w-full'>
      { loadingHeader(`lh${index}4`) }
      <div className='flex flex-col gap-4 mb-4 w-full'>
        { loadingOther(`pt${index}1`) }
        { loadingOther(`pt${index}2`) }
        { loadingOther(`pt${index}3`) }
      </div>
    </div>
  </div>
);

const loadingPresentations = (colorPrimary, colorSecondary, index = "p") => (
  <div className="bg-white-A700 shadow-md w-full max-w-[850px] min-h-[520px] justify-start items-start mt-[40px] flex flex-col" key={index}>
    <div className="w-full h-full bg-white flex-col justify-between items-center inline-flex">
      <div className="w-full flex-col justify-start items-center flex">
        <div className="w-full justify-between items-center inline-flex">
          <div className="w-full h-[84px] relative">
            <div className="w-[90%] h-[25px] object-left-top absolute rounded-br-[100px]" style={{backgroundColor: colorPrimary}} />
            <div className="w-[13%] h-full object-left-top absolute rounded-br-[50px]" style={{backgroundColor: colorSecondary}} />
          </div>
          <div className="px-5 justify-start items-start gap-2 flex">
            <div className="flex-col justify-start items-end inline-flex">
              <div className="text-zinc-600 text-xs font-normal font-['Nunito']">Año academico - Curso</div>
              <div className="text-gray-400 text-[8px] font-normal font-['Nunito']">Liceo / Colegio</div>
            </div>
            <div className="justify-start items-start flex">
              <Img className="w-[48px] h-[33px]" src="/images/img_genericlogo.png" />
            </div>
          </div>
        </div>
        <div className="w-[80%] flex-col justify-start items-start gap-4 flex mt-[80px]">
          <Skeleton className="w-[50%]" variant="rounded" height={widthLoadingIcon} animation="wave" />
          <Skeleton className="w-full" variant="rounded" height={widthLoadingIcon * 8} />
        </div>
      </div>
    </div>
    <div className="w-full justify-end items-end mt-auto flex flex-col">
      <div className="w-full h-full justify-between items-center inline-flex mt-auto">
        <div className="w-[50%] h-[31px] object-left-bottom rotate-180 rounded-bl-[100px]" style={{backgroundColor: colorPrimary}} />
        <div className="w-[22%] h-[31px] object-right-bottom rounded-tl-[50px]" style={{backgroundColor: colorSecondary}} />
      </div>
    </div>
  </div>
);

const loadingMultipleChoiceCount = (n, index = "mc") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(loadingMultipleChoice(`${index}${i}`));
  }
  return <div className="w-full">{elements}</div>;
};

const loadingTrueFalseCount = (n, index = "tf") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(loadingTrueFalse(`${index}${i}`));
  }
  return <>{elements}</>;
};

const loadingPairedTermsCount = (n, index = "pt") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(loadingPairedTerms(`${index}${i}`));
  }
  return <>{elements}</>;
};

const loadingOtherCount = (n, index = "oth") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(loadingOther(`${index}${i}`));
  }
  return <>{elements}</>;
};

const loadingPresentTypesSets = (n = 1, index = "ptys") => {
  let elements = [];
  for (let i = 0; i < n; i++) {
    elements.push(<Skeleton className="w-full rounded-xl" variant="" height={widthLoadingIcon + 60} animation="wave" key={`${index}${i}`}/>);
  }
  return <div className='grid grid-cols-3 gap-1.5 w-full'>{elements}</div>;
};

const viewPresentTitleCard = (heights = widthLoadingIcon, gap = 4, index = "vptc") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[50%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className='w-full'>
      <Skeleton className="w-full" variant="rounded" height={heights} animation="wave" />
    </div>
  </div>
);

const viewPresentTitleWithText = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-col gap-${gapsub} w-full`}>
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
      <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
    </div>
  </div>
);

const viewPresentContentWithButton = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vpcwb") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="mx-auto w-[65%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-col gap-${gapsub} w-full`}>
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
    </div>
    <div className='w-full'>
      <Skeleton className="mx-auto w-[30%]" variant="rounded" height={heights} animation={false} />
    </div>
  </div>
);

const viewPresent2ColumnContent = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresent2ColumnImageLeft = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vp2cil") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full bg-gray-100 items-center justify-center`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '8' : '24'}px]`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresent2ColumnImageRight = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vp2cir") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full bg-gray-100 items-center justify-center`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '8' : '24'}px]`} />
      </div>
    </div>
  </div>
);

const viewPresent2ImageColumns = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vp2cir") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '8' : '24'}px]`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '8' : '24'}px]`} />
      </div>
    </div>
  </div>
);

const viewPresentBarStatsWithText = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}3`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresentFunnelWithText = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col w-full items-center`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}1`} />
        <Skeleton className="w-[60%]" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}2`} />
        <Skeleton className="w-[25%]" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}3`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresentSteppedPyramidWhithText = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col w-full items-center`}>
        <Skeleton className="w-[35%]" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}1`} />
        <Skeleton className="w-[70%]" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon - 5} key={`${index}3`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresent3ColumnContent = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}4`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}5`} />
      </div>
    </div>
  </div>
);

const viewPresent3ImageColumns = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <IoImageOutline className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
    </div>
  </div>
);

const viewPresent3NestedCards = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-col gap-${gapsub} w-full`}>
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon} key={`${index}1`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon} key={`${index}2`} />
      <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 5 : widthLoadingIcon} key={`${index}3`} />
    </div>
  </div>
);

const viewPresent3Toggles = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-col gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="circular" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
      </div>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="circular" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
      </div>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="circular" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
      </div>
    </div>
  </div>
);

const viewPresentTimeLine = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-col gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
      <div className={`flex flex-row gap-${gapsub}`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
    </div>
  </div>
);

const viewPresentLargeBulletList = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub} w-[50%]`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
      <div className={`flex flex-row gap-${gapsub} w-[50%]`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub} w-[50%]`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
      <div className={`flex flex-row gap-${gapsub} w-[50%]`}>
        <Skeleton variant="rounded" width={(heights <= 15) ? 2 : widthLoadingIcon - 15} height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        <div className={`w-full flex flex-col gap-${(gapsub < 2) ? '0' : gapsub}`}>
          <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
          <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} />
        </div>
      </div>
    </div>
  </div>
);

const viewPresent3Boxes = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-start justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-start justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
      </div>
      <div className={`flex flex-col gap-${gapsub} w-full h-full bg-gray-100 items-start justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}1`} />
        <Skeleton className="w-full" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}2`} />
        <Skeleton className="w-[85%]" variant="rounded" height={(heights <= 15) ? 2 : widthLoadingIcon - 15} key={`${index}3`} />
      </div>
    </div>
  </div>
);

const viewPresentTeamPhotos = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <FaUser className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <FaUser className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <FaUser className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
    </div>
  </div>
);

const viewPresentBigNumbers = (heights = widthLoadingIcon, gap = 4, gapsub = "2", index = "vptwt") => (
  <div className={`flex flex-col gap-${gap} w-full`} key={index} >
    <div className='w-full'>
      <Skeleton className="w-[45%]" variant="rounded" height={heights} animation="wave" />
    </div>
    <div className={`flex flex-row gap-${gapsub} w-full`}>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <TbLetterX className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <TbLetterX className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
      <div className={`flex flex-row gap-${gapsub} w-full h-full bg-gray-100 items-center justify-center ${(gapsub < 2) ? 'p-1' : 'p-5'}`}>
        <TbLetterX className={`text-blue_gray-200_01 mx-auto text-[${(gapsub < 2) ? '5' : '24'}px]`} />
      </div>
    </div>
  </div>
);

export const CustomSkeleton = { 
  iconLoading, 
  loadingPage,
  loadingHeader,
  loadingTypesGuidesSets,
  loadingTypesActivitiesSets,
  loadingTypesSets,
  loadingTypes, 
  loadingOasSets,
  loadingOasSmall, 
  loadingTextSmall, 
  loadingImage,
  loadingMultipleChoiceCount,
  loadingTrueFalseCount,
  loadingPairedTermsCount,
  loadingOtherCount,
  loadingGuide,
  loadingActivity,
  loadingPresentations,
  loadingPresentTypesSets,
  loadingLibraryList,
  loadingLibraryGroup,
  viewPresentTitleCard,
  viewPresentTitleWithText,
  viewPresentContentWithButton,
  viewPresent2ColumnContent,
  viewPresent2ColumnImageLeft,
  viewPresent2ColumnImageRight,
  viewPresent2ImageColumns,
  viewPresentBarStatsWithText,
  viewPresentFunnelWithText,
  viewPresentSteppedPyramidWhithText,
  viewPresent3ColumnContent,
  viewPresent3ImageColumns,
  viewPresent3NestedCards,
  viewPresent3Toggles,
  viewPresentTimeLine,
  viewPresentLargeBulletList,
  viewPresent3Boxes,
  viewPresentTeamPhotos,
  viewPresentBigNumbers
}
import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { es } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es);
setDefaultLocale('es');

const CustomDatepicker = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name = "datepicker",
      selected = "",
      placeholder = "",
      popperPlacement = "bottom-start",
      errors = [],
      onChange,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div className={wrapClassName}>
        <DatePicker id={name} name={name} locale={es} selected={selected} onChange={onChange} className={`${className}`} placeholderText={placeholder} dateFormat="dd-MM-yyyy" popperPlacement={popperPlacement}/>
      </div>
    )
  },
);

CustomDatepicker.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
};

export { CustomDatepicker };

import React from "react";
import LayoutView from "pages/layout";

const NotFound = () => {
  return LayoutView(
    <div>
      <h1>Opps!</h1>
    </div>
  );
};

export default NotFound;

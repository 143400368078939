import React from "react";

const sizeClasses = {
  txtNunitoBold12: "font-bold font-nunito",
  txtNunitoRegular12Gray800: "font-normal font-nunito",
  txtNunitoRegular12Gray600: "font-normal font-nunito",
  txtNunitoBold17: "font-bold font-nunito",
  txtNunitoRegular21Gray100: "font-normal font-nunito",
  txtInterBold28: "font-bold font-inter",
  txtNunitoRegular21Gray700: "font-normal font-nunito",
  txtInterExtraBold40IndigoA200: "font-extrabold font-inter",
  txtNunitoRegular16Gray800: "font-normal font-nunito",
  txtInterSemiBold28: "font-inter font-semibold",
  txtInterExtraBold16: "font-extrabold font-inter",
  txtNunitoRegular16Black900: "font-normal font-nunito",
  txtInterSemiBold10: "font-inter font-semibold",
  txtNunitoRegular21: "font-normal font-nunito",
  txtNunitoBold17IndigoA200: "font-bold font-nunito",
  txtInterExtraBold40Gray100: "font-extrabold font-inter",
  txtNunitoMedium20: "font-medium font-nunito",
  txtNunitoRegular12: "font-normal font-nunito",
  txtInterBold16: "font-bold font-inter",
  txtNunitoRegular14: "font-normal font-nunito",
  txtNunitoRegular16: "font-normal font-nunito",
  txtInterBold52: "font-bold font-inter",
  txtRobotoRegular14: "font-normal font-roboto",
  txtNunitoBlack20: "font-black font-nunito",
  txtNunitoRegular16Gray600: "font-normal font-nunito",
  txtInterExtraBold24: "font-extrabold font-inter",
  txtRobotoRegular16: "font-normal font-roboto",
  txtInterRegular12: "font-inter font-normal",
  txtInterExtraBold40: "font-extrabold font-inter",
  txtDMSansRegular18: "font-dmsans font-normal",
  txtNunitoRegular21Bluegray300: "font-normal font-nunito",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
